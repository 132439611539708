/* 3840px */

:root {
  --heroText: 401px/401px 'Monument Extended Ultrabold';
  --heroMessageMainTitle: 68px/82px 'Neue Machina Regular';
  --heroMessageMainText: 108px/130px 'Neue Machina Regular';
  --heroMessageFooterBtnText: 36px/59px 'Neue Machina Regular';
  --advantageTitle: 256px/308px 'Monument Extended Ultrabold';
  --advantageDescription: 108px/130px 'Neue Machina Regular';
  --directionsMainTitleText : 192px/231px 'Monument Extended Ultrabold';
  --accordionTitle: 72px/86px 'Neue Machina Regular';
  --accordrionContent: 48px/48px 'Neue Machina Medium';
  --accordionLink: 28px/32px 'Neue Machina Regular';
}

.hello {
  position: relative;
}

.helloMenu {
  position: fixed;
  width: 100%;
  z-index: 8;
}

.start,
.hero,
.heroMessage,
.expertise,
.perfomance,
.trust {
  height: 100vh;
  min-height: 2160px;
}

.start {
  position: relative;
  z-index: 7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #000000;
}

.startLogo {
  width: 256px;
  height: 256px;
  padding: 742px 0px 80px 0px;
}

.startAlphabet {
  width: 947px;
  height: 169px;
  padding: 0px 0px 8px 0px;
}

.startCapital {
  width: 723px;
  height: 169px;
  padding: 0px 0px 359px 0px;
}

.mainScrollDownContainer,
.startScrollDownContainer,
.heroScrollDownContainer,
.expertiseScrollDownContainer,
.perfomanceScrollDownContainer,
.trustScrollDownContainer {
  position: relative;
  width: 100%;
  height: 249px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
}

.startScrollDownContainer {
  opacity: 1;
}

.mainScrollDownContainer {
  position: fixed;
  z-index: 6;
  top: 80%;
  pointer-events: none;
}

.mainScrollDown,
.startScrollDown,
.heroScrollDown,
.expertiseScrollDown,
.perfomanceScrollDown,
.trustScrollDown {
  position: relative;
  width: 249px;
  height: 249px;
  z-index: 5;
  -webkit-animation: rotate 5s infinite linear;
          animation: rotate 5s infinite linear;
}

.mainScrollDown {
  padding: 10px 10px 10px 10px;
  background-color: #000000;
  border-radius: 50%;
}

.mainScrollDownArrow,
.startScrollDownArrow,
.heroScrollDownArrow,
.experitseScrollDownArrow,
.perfomanceScrollDownArrow,
.trustScrollDownArrow {
  position: absolute;
  width: 28px;
  height: 67px;
  z-index: 5;
}

.heroHeader {
  width: 100%;
  position: fixed;
  z-index: 6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 44px 0px 0px 0px;
  pointer-events: none;
}

.heroTitleContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  padding: 0px 0px 0px 116px;
}

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 100px 0px 0px;
  pointer-events: all;
}

.menu p {
  margin: 0px 48px 0px 0px;
}

.heroMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: no-repeat url('../../../public/content/general/star.svg');
  background-size: 266px 417px;
  background-position-x: center;
  padding: 400px 0px 0px 0px;
}

.heroMainText {
  width: 2123px;
  font: var(--heroText);
  color: #FFFFFF;
}

.hero {
  z-index: 2;
}

.heroMessage {
  z-index: 5;
  background-color: #000000;
}

.expertise {
  position: relative;
  z-index: 2;
  background-color: #000000;
}

.heroBubbles {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.heroBubblesImg {
  width: 70vw;
  height: 100vh;
}

.advantageBubbles {
  position: relative;
  z-index: 3;
  width: 100vw;
  height: 100vh;
}

.directions {
  position: relative;
  z-index: 3;
  background-color: #000000;
}

.heroMessageMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #F2F2F2;
  padding: 520px 0px 0px 0px;
}

.heroMessageMainTitle {
  padding: 0px 165px 0px 0px;
}

.heroMessageMainTitleText {
  font: var(--heroMessageMainTitle);
}

.heroMessageMainText {
  width: 2403px;
  font: var(--heroMessageMainText);
}

.heroMessageMainTextSecond {
  padding: 189px 0px 189px 0px;
  color: #888888;
}

.teamExperts {
  color: #F2F2F2;
}

.heroMessageFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 90px 209px 0px 0px;
}

.heroMessageFooterBtnText {
  padding: 0px 0px 32px 0px;
  text-transform: uppercase;
  font: var(--heroMessageFooterBtnText);
  color: #FFFFFF;
}

.heroMessageArrow {
  width: 30px;
  height: 70px;
}

.advantageHeader {
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 44px 100px 0px 116px;
}

.advantageMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.advantageMenu p {
  margin: 0px 48px 0px 0px;
}

.expertiseMain,
.perfomanceMain,
.trustMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.titlePoint {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #D2FC04;
}

.expertiseMainTitle,
.perfomanceMainTitle,
.trustMainTitle {
  padding: 0px 0px 48px 0px;
  font: var(--advantageTitle);
}

.expertiseMainDescription,
.perfomanceMainDescription,
.trustMainDescription {
  font: var(--advantageDescription);
}

.expertiseMain {
  padding: 633px 0px 0px 0px;
}

.expertiseMainTitle {
  padding: 0px 0px 48px 0px;
}

.expertiseMainDescription {
  width: 1784px;
}

.expertiseScrollDownContainer {
  padding: 280px 0px 0px 0px;
}

.perfomanceMain {
  padding: 568px 0px 0px 0px;
}

.perfomanceMainDescription {
  width: 2292px;
}

.perfomanceScrollDownContainer {
  padding: 215px 0px 0px 0px;
}

.trustMain {
  padding: 633px 0px 0px 0px;
}

.trustMainDescription {
  width: 2292px;
}

.trustScrollDownContainer {
  padding: 280px 0px 0px 0px;
}

.directionsMain {
  position: relative;
  height: 1443px;
  padding: 328px 0px 0px 0px;
}

.directionsMainTitle{
  position: absolute;
  top: 50%;
  left: 20%;
}

.directionsMainTitleText {
  width: 1582px;
  font: var(--directionsMainTitleText);
  color: #FFFFFF;
}

.dirctionsImgQlobus {
  position: absolute;
  top: 19%;
  left: 60%;
  width: 1150px;
  height: 1150px;
  z-index: 2;
  opacity: 0.3;
}

.directionsGif {
  position: absolute;
  top: 28%;
  left: 59%;
  width: 1189px;
  height: 900px;
  z-index: 1;
}

.accordionList {
  width: 2107px;
  padding: 363px 0px 600px 636px;
}

.accordionContent::-webkit-scrollbar { 
  width: 0; 
}

.accordion {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
}

.accordionSection {
  display: flex;
  flex-direction: column;
}

.accordionTitle {
  padding: 97px 0px 120px 0px;
  font: var(--accordionTitle);
  color: #888888;
  transition: color 0.6s ease;
}

.accordionItem {
  border-top: 3px solid #2E2E2E;
}

.accordionIcon {
  background-size: 120px;
  width: 120px;
  height: 120px;
  margin: 0 20px 0 auto;
  transform: rotate(0deg);
  transition: transform 0.6s ease-in-out;
}

.accordionIcon circle {
  fill:#171615;
  transition: fill 0.3s ease-in-out;
}

.accordionIcon path {
  fill: #FFFFFF;
  transition: fill 0.3s ease-in-out;
}

.accordionIcon:hover circle {
  fill:#D2FC04;
  transition: fill 0.3s ease-in-out;
}

.accordionIcon:hover path {
  fill: #1A1A1A;
  transition: fill 0.3s ease-in-out;
}

.rotate {
  transform: rotate(-45deg);
}

.accordionContent {
  overflow: auto;
  transition: max-height 0.6s ease-in-out;
}

.accordionText {
  padding: 0px 0px 108px 0px;
  font: var(--accordrionContent);
  color: #888888;
  transition: color 0.6s ease-in-out;
}

.active {
  color: #FFFFFF;
  transition: color 0.5s ease-in-out;
}

.accordionLink {
  width: 200px;
  padding: 0px 0px 12px 0px;
  background: no-repeat url('../../../public/content/general/link-arrow.svg');
  background-position: 100% 13%;
  background-size: 24px;
  border-bottom: 3px solid #D2FC04;
  font: var(--accordionLink);
  text-transform: uppercase;
  color: #D2FC04;
}

@-webkit-keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --heroText: 200px/200px 'Monument Extended Ultrabold';
    --heroMessageMainTitle: 34px/41px 'Neue Machina Regular';
    --heroMessageMainText: 54px/65px 'Neue Machina Regular';
    --heroMessageFooterBtnText: 18px/29px 'Neue Machina Regular';
    --advantageTitle: 128px/154px 'Monument Extended Ultrabold';
    --advantageDescription: 54px/65px 'Neue Machina Regular';
    --directionsMainTitleText: 96px/115px 'Monument Extended Ultrabold';
    --accordionTitle: 36px/43px 'Neue Machina Regular';
    --accordrionContent: 24px/24px 'Neue Machina Medium';
    --accordionLink: 14px/17px 'Neue Machina Regular';
  }

  .start,
  .hero,
  .heroMessage,
  .expertise,
  .perfomance,
  .trust {
    min-height: 1080px;
  }

  .startLogo {
    width: 128px;
    height: 128px;
    padding: 370px 0px 40px 0px;
  }

  .startAlphabet {
    width: 472px;
    height: 84px;
    padding: 0px 0px 4px 0px;
  }

  .startCapital {
    width: 360px;
    height: 84px;
    padding: 0px 0px 179px 0px;
  }

  .mainScrollDownContainer,
  .startScrollDownContainer,
  .heroScrollDownContainer,
  .expertiseScrollDownContainer,
  .perfomanceScrollDownContainer,
  .trustScrollDownContainer {
    height: 124px;
  }

  .mainScrollDown,
  .startScrollDown,
  .heroScrollDown,
  .expertiseScrollDown,
  .perfomanceScrollDown,
  .trustScrollDown {
    width: 124px;
    height: 124px;
  }

  .mainScrollDownArrow,
  .startScrollDownArrow,
  .heroScrollDownArrow,
  .experitseScrollDownArrow,
  .perfomanceScrollDownArrow,
  .trustScrollDownArrow {
    width: 14px;
    height: 38px;
  }

  .heroTitleContainer {
    padding: 0px 0px 0px 58px;
  }

  .menu {
    padding: 0px 50px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMain {
    padding: 200px 0px 0px 0px;
    background-size: 133px 208px;
  }

  .heroMainText {
    width: 1058px;
  }

  .heroScrollDownContainer {
    padding: 49px 0px 0px 0px;
  }

  /* .heroBubbles {
    width: 1600px;
  } */

  .heroMessageMain {
    padding: 250px 0px 0px 0px;
  }

  .heroMessageMainTitle {
    padding: 0px 81px 0px 0px;
  }

  .heroMessageMainText {
    width: 1197px;
  }

  .heroMessageMainTextSecond {
    padding: 94px 0px 94px 0px;
  }

  .heroMessageFooter {
    padding: 43px 104px 0px 0px;
  }

  .heroMessageFooterBtnText {
    padding: 0px 0px 16px 0px;
  }

  .heroMessageArrow {
    width: 14px;
    height: 31px;
  }

  .advantageHeader {
    padding: 22px 45px 0px 58px;
  }

  .advantageMenu p {
    margin: 0px 22px 0px 0px;
  }

  .expertiseMainTitle,
  .perfomanceMainTitle,
  .trustMainTitle {
    padding: 0px 0px 24px 0px;
  }

  .titlePoint {
    width: 8px;
    height: 8px;
  }

  .expertiseMain {
    padding: 315px 0px 0px 0px;
  }

  .expertiseMainDescription {
    width: 889px;
  }

  .expertiseScrollDownContainer {
    padding: 139px 0px 0px 0px;
  }

  .perfomanceMain {
    padding: 282px 0px 0px 0px;
  }

  .perfomanceMainDescription {
    width: 1142px;
  }

  .perfomanceScrollDownContainer {
    padding: 106px 0px 0px 0px;
  }

  .trustMain {
    padding: 315px 0px 0px 0px;
  }

  .trustMainDescription {
    width: 1142px;
  }

  .trustScrollDownContainer {
    padding: 139px 0px 0px 0px;
  }

  .directionsMain {
    height: 719px;
    padding: 163px 0px 0px 0px;
  }
  
  .directionsMainTitle{
    top: 50%;
    left: 23%;
  }
  
  .directionsMainTitleText {
    width: 800px;
  }
  
  .dirctionsImgQlobus {
    top: 19%;
    left: 58%;
    width: 572px;
    height: 572px;
  }
  
  .directionsGif {
    top: 28%;
    left: 57%;
    width: 592px;
    height: 443px;
  }
  
  .accordionList {
    width: 1050px;
    padding: 181px 0px 374px 430px;
  }
  
  .accordionItem {
    border-top: 1.5px solid #2E2E2E;
  }

  .accordionTitle {
    padding: 48px 0px 50px 0px;
  }
    
  .accordionIcon {
    width: 60px;
    height: 60px;
    background-size: 60px;
    margin: 0 20px 0 auto;
  }
    
  .accordionText {
    padding: 0px 0px 60px 0px;
  }
  
  .accordionLink {
    width: 100px;
    padding: 0px 0px 6px 0px;
    background-position: 100% 30%;
    background-size: 11px;
    border-bottom: 1.5px solid #D2FC04;
  }

}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --heroText: 180px/180px 'Monument Extended Ultrabold';
    --heroMessageMainTitle: 16px/19px 'Neue Machina Regular';
    --heroMessageMainText: 36px/43px 'Neue Machina Regular';
    --heroMessageFooterBtnText: 12px/20px 'Neue Machina Regular';
    --advantageTitle: 90px/108px 'Monument Extended Ultrabold';
    --advantageDescription: 36px/43px 'Neue Machina Regular';
    --directionsMainTitleText: 64px/77px 'Monument Extended Ultrabold';
    --accordionTitle: 36px/43px 'Neue Machina Regular';
    --accordrionContent: 24px/24px 'Neue Machina Medium';
    --accordionLink: 14px/17px 'Neue Machina Regular';
  }

  .start,
  .hero,
  .heroMessage,
  .expertise,
  .perfomance,
  .trust {
    min-height: 720px;
  }

  .startLogo {
    width: 64px;
    height: 64px;
    padding: 275px 0px 20px 0px;
  }

  .startAlphabet {
    width: 236px;
    height: 42px;
    padding: 0px 0px 2px 0px;
  }

  .startCapital {
    width: 180px;
    height: 42px;
    padding: 0px 0px 134px 0px;
  }

  .mainScrollDownContainer,
  .startScrollDownContainer,
  .heroScrollDownContainer,
  .expertiseScrollDownContainer,
  .perfomanceScrollDownContainer,
  .trustScrollDownContainer {
    height: 108px;
  }

  .mainScrollDown,
  .startScrollDown,
  .heroScrollDown,
  .expertiseScrollDown,
  .perfomanceScrollDown,
  .trustScrollDown {
    width: 108px;
    height: 108px;
  }

  .mainScrollDownArrow,
  .startScrollDownArrow,
  .heroScrollDownArrow,
  .experitseScrollDownArrow,
  .perfomanceScrollDownArrow,
  .trustScrollDownArrow {
    width: 12px;
    height: 33px;
  }

  .heroTitleContainer {
    padding: 0px 0px 0px 32px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .heroMain {
    padding: 50px 0px 0px 0px;
    background-position: 80% 5%;
  }

  .heroMainText {
    width: 952px;
  }

  .heroScrollDownContainer {
    padding: 0px 0px 0px 0px;
  }

  .heroMessageMain {
    padding: 170px 0px 0px 0px;
  }

  .heroMessageMainTitle {
    padding: 0px 102px 0px 0px;
  }

  .heroMessageMainText {
    width: 812px;
  }

  .heroMessageMainTextSecond {
    padding: 64px 0px 64px 0px;
  }

  .heroMessageFooter {
    padding: 36px 60px 0px 0px;
  }

  .heroMessageFooterBtnText {
    padding: 0px 0px 11px 0px;
  }

  .heroMessageArrow {
    width: 10px;
    height: 20px;
  }

  .advantageHeader {
    padding: 22px 27px 0px 32px;
  }

  .advantageMenu p {
    margin: 0px 22px 0px 0px;
  }

  .expertiseMainTitle,
  .perfomanceMainTitle,
  .trustMainTitle {
    padding: 0px 0px 18px 0px;
  }

  .expertiseMain {
    padding: 168px 0px 0px 0px;
  }

  .expertiseMainDescription {
    width: 640px;
  }

  .expertiseScrollDownContainer {
    padding: 80px 0px 0px 0px;
  }

  .perfomanceMain {
    padding: 146px 0px 0px 0px;
  }

  .perfomanceMainDescription {
    width: 800px;
  }

  .perfomanceScrollDownContainer {
    padding: 59px 0px 0px 0px;
  }

  .trustMain {
    padding: 168px 0px 0px 0px;
  }

  .trustMainDescription {
    width: 640px;
  }

  .trustScrollDownContainer {
    padding: 80px 0px 0px 0px;
  }

  .directionsMain {
    height: 480px;
    padding: 110px 0px 0px 0px;
  }
  
  .directionsMainTitle{
    top: 50%;
    left: 23%;
  }
  
  .directionsMainTitleText {
    width: 526px;
  }
  
  .dirctionsImgQlobus {
    top: 19%;
    left: 58%;
    width: 382px;
    height: 382px;
  }
  
  .directionsGif {
    top: 28%;
    left: 57%;
    width: 395px;
    height: 295px;
  }
  
  .accordionList {
    width: 700px;
    padding: 120px 0px 250px 287px;
  }

  .accordionItem {
    border-top: 1px solid #2E2E2E;
  }

  .accordionTitle {
    padding: 32px 0px 40px 0px;
  }
    
  .accordionIcon {
    width: 40px;
    height: 40px;
    background-size: 40px;
    margin: 0 20px 0 auto;
  }
    
  .accordionText {
    padding: 0px 0px 54px 0px;
  }
  
  .accordionLink {
    width: 100px;
    padding: 0px 0px 4px 0px;
    background-position: 100% 30%;
    background-size: 9px;
    border-bottom: 1px solid #D2FC04;
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --heroText: 100px/100px 'Monument Extended Ultrabold';
    --heroMessageMainTitle: 16px/19px 'Neue Machina Regular';
    --heroMessageMainText: 36px/43px 'Neue Machina Regular';
    --heroMessageFooterBtnText: 12px/20px 'Neue Machina Regular';
    --advantageTitle: 64px/77px 'Monument Extended Ultrabold';
    --advantageDescription: 36px/43px 'Neue Machina Regular';
    --directionsMainTitleText: 64px/77px 'Monument Extended Ultrabold';
    --accordionTitle: 36px/43px 'Neue Machina Regular';
    --accordrionContent: 24px/24px 'Neue Machina Medium';
    --accordionLink: 14px/17px 'Neue Machina Regular';
  }

  .start,
  .hero,
  .heroMessage,
  .expertise,
  .perfomance,
  .trust {
    min-height: 1024px;
  }

  .startLogo {
    width: 64px;
    height: 64px;
    padding: 427px 0px 20px 0px;
  }

  .startAlphabet {
    width: 236px;
    height: 42px;
    padding: 0px 0px 2px 0px;
  }

  .startCapital {
    width: 180px;
    height: 42px;
    padding: 0px 0px 286px 0px;
  }

  .mainScrollDown,
  .expertiseScrollDown,
  .perfomanceScrollDown,
  .trustScrollDown {
    width: 96px;
    height: 96px;
  }

  .mainScrollDownArrow,
  .experitseScrollDownArrow,
  .perfomanceScrollDownArrow,
  .trustScrollDownArrow {
    width: 11px;
    height: 30px;
  }

  .heroMain {
    padding: 350px 0px 0px 0px;
    background: none;
  }

  .heroMainText {
    width: 529px;
  }

  .heroScrollDownContainer {
    padding: 221px 0px 0px 0px;
  }

  .heroBubblesImg {
    width: 100vw;
  }

  .heroMessageMain {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; 
    -webkit-box-align: center; 
        -ms-flex-align: center; 
            align-items: center;
    padding: 178px 0px 0px 0px;
  } 

  .heroMessageMainTitle {
    padding: 0px 493px 103px 0px;
  }

  .heroMessageMainText {
    width: 598px;
  }

  .heroMessageMainTextSecond {
    padding: 64px 0px 64px 0px;
  }

  .heroMessageFooter {
    padding: 199px 54px 0px 0px;
  }

  .heroMessageFooterBtnText {
    padding: 0px 0px 11px 0px;
  }

  .heroMessageArrow {
    width: 10px;
    height: 20px;
  }

  .advantageHeader {
    padding: 22px 27px 0px 32px;
  }

  .advantageMenu p {
    margin: 0px 22px 0px 0px;
  }

  .expertiseMainTitle,
  .perfomanceMainTitle,
  .trustMainTitle {
    padding: 0px 0px 24px 0px;
  }

  .expertiseMain {
    padding: 335px 0px 0px 0px;
  }

  .expertiseMainDescription {
    width: 640px;
  }

  .expertiseScrollDownContainer {
    padding: 247px 0px 0px 0px;
  }

  .perfomanceMain {
    padding: 292px 0px 0px 0px;
  }

  .perfomanceMainDescription {
    width: 640px;
  }

  .perfomanceScrollDownContainer {
    padding: 204px 0px 0px 0px;
  }

  .trustMain {
    padding: 335px 0px 0px 0px;
  }

  .trustMainDescription {
    width: 640px;
  }

  .trustScrollDownContainer {
    padding: 247px 0px 0px 0px;
  }

  .directionsMain {
    height: 500px;
    padding: 110px 0px 0px 0px;
  }
  
  .directionsMainTitle{
    top: 50%;
    left: 5%;
  }
  
  .directionsMainTitleText {
    width: 526px;
  }
  
  .dirctionsImgQlobus {
    top: 19%;
    left: 25%;
    width: 382px;
    height: 382px;
  }
  
  .directionsGif {
    display: none;
  }
  
  .accordionList {
    padding: 120px 0px 430px 31px;
  }

  .accordionTitle {
    padding: 48px 0px 60px 0px;
  }
      
}

/* 375px */

@media (max-width: 767px) {

  :root {
    --heroText: 64px/64px 'Monument Extended Ultrabold';
    --heroMessageMainTitle: 16px/19px 'Neue Machina Regular';
    --heroMessageMainText: 24px/24px 'Neue Machina Medium';
    --heroMessageFooterBtnText: 12px/20px 'Neue Machina Regular';
    --advantageTitle: 36px/43px 'Monument Extended Ultrabold';
    --advantageDescription: 20px/24px 'Neue Machina Medium';
    --directionsMainTitleText: 36px/43px 'Monument Extended Ultrabold';
    --accordionTitle: 36px/43px 'Neue Machina Regular';
    --accordrionContent: 20px/24px 'Neue Machina Medium';
    --accordionLink: 14px/17px 'Neue Machina Regular';
  }

  .start,
  .hero,
  .heroMessage,
  .expertise,
  .perfomance,
  .trust {
    min-height: 667px;
  }

  .startLogo {
    width: 64px;
    height: 64px;
    padding: 186px 0px 20px 0px;
  }

  .startAlphabet {
    width: 236px;
    height: 42px;
    padding: 0px 0px 2px 0px;
  }

  .startCapital {
    width: 180px;
    height: 42px;
    padding: 0px 0px 0px 0px;
  }

  .mainScrollDownContainer,
  .startScrollDownContainer,
  .heroScrollDownContainer,
  .expertiseScrollDownContainer,
  .perfomanceScrollDownContainer,
  .trustScrollDownContainer {
    /* display: none; */
  }

  .startScrollDownContainer {
    padding: 200px 0px 0px 0px;
  }

  .heroHeader {
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    padding: 22px 0px 0px 0px;
  }

  .heroTitleContainer {
    display: none;
  }

  .menu {
    padding: 0px 11px 0px 0px;
  }

  .hero,
  .heroMessage,
  .expertise,
  .perfomance,
  .trust {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .heroMain {
    padding: 100px 0px 0px 0px;
  }

  .heroMainText {
    width: 339px;
  }

  .heroMessageMain {
    padding: 215px 0px 0px 0px;
  }

  .heroMessageMainTitle {
    display: none;
    padding: 0px 493px 103px 0px;
  }

  .heroMessageMainText {
    width: 352px;
  }

  .heroMessageMainTextSecond {
    padding: 64px 0px 64px 0px;
  }

  .heroMessageFooter {
    display: none;
  }

  .expertiseMainTitle,
  .perfomanceMainTitle,
  .trustMainTitle {
    padding: 0px 0px 24px 0px;
  }

  .expertiseMain {
    padding: 0px 0px 0px 0px;
  }

  .expertiseMainDescription {
    width: 351px;
  }

  .perfomanceMain {
    padding: 0px 0px 0px 0px;
  }

  .perfomanceMainDescription {
    width: 351px;
  }

  .trustMain {
    padding: 0px 0px 0px 0px;
  }

  .trustMainDescription {
    width: 351px;
  }

  .directionsMain {
    height: 253px;
    padding: 124px 0px 0px 0px;
  }
  
  .directionsMainTitle{
    top: 50%;
    left: 5%;
  }
  
  .directionsMainTitleText {
    width: 296px;
  }
  
  .dirctionsImgQlobus {
    display: none;
  }
    
  .accordionList {
    width: 351px;
    padding: 160px 0px 360px 12px;
  }

  .accordionTitle {
    text-align: left;
    padding: 32px 0px 40px 0px;
  }
        
  .accordionText {
    padding: 0px 0px 36px 0px;
  }
  
}
