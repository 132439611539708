/* 3840px */

body {
  overflow-x: hidden;
}

:root {
  --mainText: 361px/361px 'Monument Extended Ultrabold';
  --mainDescription: 100px/130px 'Neue Machina Regular';
  --button: 36px/60px 'Neue Machina Regular';
  --ourMissionTitleFirst: 193px/193px 'Monument Extended Ultrabold';
  --ourMissionDescription: 72px/87px 'Neue Machina Light';
  --expertiseTitle: 302px/361px 'Monument Extended Ultrabold';
  --expertiseDescription: 108px/130px 'Neue Machina Regular';
  --expertiseAdvantagesItemTitle: 144px/173px 'Neue Machina Regular';
  --expertiseAdvantagesItemText: 42px/51px 'Neue Machina Regular';
  --partnersHeroTitle: 361px/434px 'Monument Extended Ultrabold';
}

.heroContainer,
.ourMission,
.expertise,
.perfomance,
.partners {
  max-width: 3840px;
  margin: 0 auto;
}

.aboutMenu {
  position: fixed;
  width: 100%;
  z-index: 5;
}

.hero {
  min-height: 2160px;
  background-color: #171615;
  height: 100vh;
}

.heroHeader {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  padding: 66px 0px 0px 0px;
}

.titlePoint {
  width: 16px;
  height: 16px;
  margin: 0px 0px 0px 54px;
  border-radius: 50%;
  background-color: #D2FC04;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 72px 0px 0px;
}

.menu p {
  margin: 0px 114px 0px 0px;
}

.heroMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 320px 0px 0px 0px;
}

.heroMainText {
  width: 2312px;
  font: var(--mainText);
  color: #FFFFFF;
}

.heroMainDescription {
  padding: 88px 1560px 15px 725px;
  font: var(--mainDescription);
  color: #FFFFFF;
}

.heroButton {
  height: 157px;
  padding: 0px 0px 0px 3222px;
  background: no-repeat url('../../../public/content/general/arrow.svg');
  background-size: 31px 54px;
  background-position: 85% 80%;
}

.heroButtonText {
  font: var(--button);
  color: #FFFFFF;
  text-transform: uppercase;
}

.ourMission,
.expertise,
.perfomance {
  background-color: #000000;
  min-height: 2160px;
}

.ourMission {
  padding: 620px 0px 700px 0px;
  max-width: 3840px;
  margin: 0 auto;
}

.ourMissionContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 4280px;
  height: 3185px;
	transform: rotateZ(90deg) scale(.67, 1);
}

@keyframes circle {
	from{
		transform: rotate(0deg) translate(-2140px) rotate(0) scale(1.5, 1);
	}
	to{
		transform: rotate(360deg) translate(-2140px) rotate(-360deg) scale(1.5, 1);
	}
}

@keyframes star {
	from{
		transform: rotate(180deg) translate(-1900px) rotate(-180deg) scale(1.5, 1);
	}
	to{
		transform: rotate(540deg) translate(-1900px) rotate(-540deg) scale(1.5, 1);
	}
}

@keyframes smallStar {
	from{
		transform: rotate(0deg) translate(-1900px) rotate(0deg) scale(1.5, 1);
	}
	to{
		transform: rotate(360deg) translate(-1900px) rotate(-360deg) scale(1.5, 1);
	}
}

.dot {
  position: absolute;
  z-index: 2;
	width: 360px;
	height: 360px;
	/* margin: 0px -109px -41px -50px; */
  /* top: 50%;
	left: 50%; */
  top: 99%;
  left: 83%;
  background: no-repeat center url('../../../public/content/services/partner-circle.png');
  background-size: 360px;
	transform: scale(1.5, 1);
  /* -webkit-animation: circle 30s linear infinite;
	animation: circle 30s linear infinite; */
}

.star {
  position: absolute;
  z-index: 2;
	width: 223px;
	height: 364px;
  top: 96%;
	left: 19%;
  background: no-repeat url('../../../public/content/general/star.svg');
  background-size: 223px 365px;
	transform: rotate(90deg) scale(1.5);
  /* -webkit-animation: star 30s linear infinite;
	animation: star 30s linear infinite; */
}

.smallStar {
  position: absolute;
  z-index: 2;
	width: 184px;
	height: 181px;
  top: 8%;
	left: 81%;
  background: no-repeat url('../../../public/content/whoWeAre/smallStar.svg');
  background-size: 184px 181px;
  transform: scale(1.5);
  /* -webkit-animation: smallStar 20s 1s linear infinite;
	animation: smallStar 20s linear 1s infinite; */
}

.parnerCircleOrbit {
  position: absolute;
  width: 4280px;
  height: 3185px;
  top: -10%;
  left: 0%;
  z-index: 1;
  padding: 500px 100px 500px 100px;
  border-radius: 60%;
  border: 3px solid #2E2E2E;
}

.starOrbit {
  position: absolute;
  width: 3700px;
  height: 3185px;
  top: 0%;
  left: 0%;
  z-index: 1;
  padding: 200px 400px 200px 400px;
  border-radius: 60%;
  border: 3px solid #2E2E2E;
}

.ourMissionText {
  position: absolute;
  width: 1900px;
  top: 31%;
  left: 26%;
  transform: rotate(-90deg) scale(1, 1.5);
  z-index: 3;
  text-align: center;
  color: #FFFFFF;
}

.ourMissionTitleFirst {
  font: var(--ourMissionTitleFirst);
}

.ourMissionTitleSecond {
  font: var(--mainText);
}

.ourMissionSubTitle {
  padding: 144px 0px 144px 0px;
  font: var(--expertiseDescription);
}

.ourMissionDescription {
  font: var(--ourMissionDescription);
}

.ourMissionImg {
  position: absolute;
  top: 15%;
  left: 24%;
  width: 2500px;
  height: 2500px;
  transform: rotate(-90deg) scale(1, 1.5);
  z-index: 2;
}

.ourMissionArrow {
  position: absolute;
  width: 72px;
  height: 193px;
  top: 52%;
  left: 83%;
  z-index: 2;
  transform: rotate(-90deg) scale(1, 1.5);
}

.expertiseTitle {
  padding: 0px 0px 193px 696px;
  position: relative;
  z-index: 2;
  font: var(--expertiseTitle);
  color: #FFFFFF;
}

.expertiseDescription {
  position: relative;
  height: 550px;
}

.expertiseDescriptionText {
  position: absolute;
  width: 1818px;
  left: 696px;
  z-index: 2;
  font: var(--expertiseDescription);
  color: #FFFFFF;
}

.expertiseDescriptionImg {
  position: absolute;
  width: 1505px;
  height: 590px;
  top: -20%;
  left: 60%;
  z-index: 1;
}

.expertiseAdvantages {
  position: relative;
}

.expertiseAdvantagesItem,
.perfomanceAdvantagesItem {
  position: absolute;
}

.expertiseAdvantagesItem:nth-child(1) {
  width: 1023px;
  height: 1023px;
  left: 7%;
  z-index: 2;
}

.expertiseAdvantagesItem:nth-child(2) {
  width: 208px;
  padding: 350px 404px 365px 410px;
  left: 25%;
  z-index: 1;
}

.expertiseAdvantagesItem:nth-child(3) {
  width: 1023px;
  height: 1023px;
  left: 45%;
  z-index: 2;
}

.expertiseAdvantagesItem:nth-child(4) {
  width: 1023px;
  height: 1023px;
  left: 65%;
  z-index: 2;
}

.expertiseAdvantagesItemTitle,
.perfomanceAdvantagesItemTitle  {
  padding: 0px 0px 84px 0px;
  text-align: center;
  font: var(--expertiseAdvantagesItemTitle);
  color: #FFFFFF;
}

.expertiseAdvantagesItemText,
.perfomanceAdvantagesItemText {
  text-align: center;
  font: var(--expertiseAdvantagesItemText);
  color: #888888;
}

.expertiseAdvantagesItemGif,
.perfomanceAdvantagesItemGif {
  position: relative;
  height: 1023px;
}

.expertiseAdvantagesItemGifText,
.perfomanceAdvantagesItemGifText {
  position: absolute;
  z-index: 2;
}

.expertiseAdvantagesItemGifImg {
  width: 1445px;
  height: 1445px;
  position: absolute;
  z-index: 1;
  top: -56%;
  left: -280%;
}

.perfomance {
  padding: 911px 0px 300px 0px;
}

.perfomanceHero {
  position: relative;
}

.perfomanceMain {
  position: absolute;
  width: 1987px;
  left: 17%;
  z-index: 2;
}

.perfomanceHeroTitle {
  font: var(--partnersHeroTitle);
  color: #FFFFFF;
}

.perfomanceHeroText {
  width: 1625px;
  padding: 90px 0px 0px 0px;
  font: var(--expertiseDescription);
  color: #FFFFFF;
}

.perfomanceHeroImg {
  position: absolute;
  width: 1505px;
  height: 1505px;
  left: 60%;
  z-index: 1;
}

.perfomanceAdvantages {
  position: relative;
  padding: 1850px 0px 0px 0px;
}

.perfomanceAdvantagesItem:nth-child(1) {
  width: 370px;
  padding: 350px 404px 313px 417px;
  left: 7%;
}

.perfomanceAdvantagesItem:nth-child(2) {
  width: 1023px;
  height: 1023px;
  left: 28%;
  z-index: 1;
}

.perfomanceAdvantagesItem:nth-child(3) {
  width: 1023px;
  height: 1023px;
  left: 48%;
}

.perfomanceAdvantagesItem:nth-child(4) {
  width: 1023px;
  height: 1023px;
  left: 68%;
}

.perfomanceAdvantagesItemGifImg {
  width: 1445px;
  height: 1445px;
  position: absolute;
  z-index: 1;
  top: -56%;
  left: -150%;
}

.partners {
  position: relative;
  min-height: 2160px;
  padding: 1500px 0px 883px 0px;
}

.partnersHero {
  position: absolute;
  width: 2458px;
  left: 18%;
  z-index: 2;
}

.partnersHeroTitle {
  font: var(--partnersHeroTitle);
  color: #FFFFFF;
}

.partnersHeroText {
  padding: 193px 0px 0px 0px;
  font: var(--expertiseDescription);
  color: #FFFFFF;
}

.partnersImg {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 15%;
  width: 2878px;
  height: 2878px;
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --mainText: 180px/180px 'Monument Extended Ultrabold';
    --mainDescription: 54px/65px 'Neue Machina Regular';
    --button: 18px/30px 'Neue Machina Regular';
    --ourMissionTitleFirst: 96px/96px 'Monument Extended Ultrabold';
    --ourMissionDescription: 36px/43px 'Neue Machina Light';
    --expertiseTitle: 150px/180px 'Monument Extended Ultrabold';
    --expertiseDescription: 54px/65px 'Neue Machina Regular';
    --expertiseAdvantagesItemTitle: 72px/86px 'Neue Machina Regular';
    --expertiseAdvantagesItemText: 21px/25px 'Neue Machina Regular';
    --partnersHeroTitle: 180px/216px 'Monument Extended Ultrabold';
  }

  .heroContainer,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    max-width: 1920px;
  }

  .hero,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    min-height: 1080px;
  }

  .heroMain {
    padding: 160px 0px 0px 0px;
  }

  .heroMainText {
    width: 1152px;
  }

  .heroHeader {
    padding: 43px 0px 0px 0px;
  }

  .titlePoint {
    width: 8px;
    height: 8px;
    margin: 0px 0px 0px 48px;
  }

  .menu {
    padding: 0px 33px 0px 0px;
  }

  .menu p {
    margin: 0px 33px 0px 0px;
  }

  .heroMainDescription {
    padding: 43px 776px 8px 362px;
  }

  .heroButton {
    height: 78px;
    padding: 0px 0px 0px 1605px;
    background-size: 15px 27px;
  }

  .ourMission {
    padding: 300px 0px 370px 0px;
    max-width: 1920px;
  }
  
  .ourMissionContainer {
    width: 2140px;
    height: 1587px;
  }
    
  @keyframes circle {
    from{
      transform: rotate(0deg) translate(-1070px) rotate(0) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-1070px) rotate(-360deg) scale(1.5, 1);
    }
  }

  @keyframes star {
    from{
      transform: rotate(180deg) translate(-950px) rotate(-180deg) scale(1.5, 1);
    }
    to{
      transform: rotate(540deg) translate(-950px) rotate(-540deg) scale(1.5, 1);
    }
  }
  
  @keyframes smallStar {
    from{
      transform: rotate(0deg) translate(-950px) rotate(0deg) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-950px) rotate(-360deg) scale(1.5, 1);
    }
  }
    
  .dot {
    width: 180px;
    height: 180px;
    background-size: 180px;
  }

  .star {
    width: 111px;
    height: 180px;
    background-size: 111px 180px;
  }
  
  .smallStar {
    width: 92px;
    height: 91px;
    margin: 0px 0px 0px 0px;
    background-size: 92px 91px;
  }
    
  .parnerCircleOrbit {
    width: 2140px;
    height: 1587px;
    top: -10%;
    left: 0%;
    z-index: 1;
    padding: 250px 50px 250px 50px;
    border-radius: 60%;
    border: 1.5px solid #2E2E2E;
  }

  .starOrbit {
    width: 1845px;
    height: 1587px;
    top: 0%;
    left: 0%;
    z-index: 1;
    padding: 100px 200px 100px 200px;
    border-radius: 60%;
    border: 1.5px solid #2E2E2E;
  }
  
  .ourMissionImg {
    width: 1250px;
    height: 1250px;
    transform: rotate(-90deg) scale(1, 1.5);
  }

  .ourMissionArrow {
    width: 36px;
    height: 96px;
    top: 52%;
    left: 83%;
  }

  .ourMissionText {
    width: 950px;
    transform: rotate(-90deg) scale(1, 1.5);
  }
    
  .ourMissionSubTitle {
    padding: 72px 0px 72px 0px;
  }
    
  .expertiseTitle {
    padding: 0px 0px 96px 349px;
  }
  
  .expertiseDescription {
    height: 270px;
  }
  
  .expertiseDescriptionText {
    width: 906px;
    left: 349px;
  }
  
  .expertiseDescriptionImg {
    width: 750px;
    height: 750px;
    top: -100%;
    left: 60%;
  }

  .expertiseAdvantages {
    padding: 100px 0px 0px 0px;
  }
      
  .expertiseAdvantagesItem:nth-child(1),
  .expertiseAdvantagesItem:nth-child(3),
  .expertiseAdvantagesItem:nth-child(4) {
    width: 510px;
    height: 510px;
  }
  
  .expertiseAdvantagesItem:nth-child(2) {
    width: 104px;
    padding: 175px 202px 182px 205px;
    border: none;
    z-index: 1;
  }
      
  .expertiseAdvantagesItemTitle,
  .perfomanceAdvantagesItemTitle  {
    padding: 0px 0px 42px 0px;
  }
    
  .expertiseAdvantagesItemGif,
  .perfomanceAdvantagesItemGif {
    height: 1023px;
  }
    
  .expertiseAdvantagesItemGifImg {
    width: 710px;
    height: 710px;
    top: -28%;
  }

  .perfomance {
    padding: 454px 0px 451px 0px;
  }
    
  .perfomanceMain {
    width: 990px;
    left: 18%;
  }
    
  .perfomanceHeroText {
    width: 906px;
    padding: 45px 0px 0px 0px;
  }
  
  .perfomanceHeroImg {
    width: 750px;
    height: 750px;
    left: 60%;
  }
  
  .perfomanceAdvantages {
    padding: 1000px 0px 0px 0px;
  }
  
  .perfomanceAdvantagesItem:nth-child(1) {
    width: 185px;
    padding: 201px 164px 223px 160px;
  }
  
  .perfomanceAdvantagesItem:nth-child(2),
  .perfomanceAdvantagesItem:nth-child(3),
  .perfomanceAdvantagesItem:nth-child(4) {
    width: 510px;
    height: 510px;
  }
    
  .perfomanceAdvantagesItemGifImg {
    width: 730px;
    height: 730px;
    top: -32%;
  }

  .partners {
    padding: 451px 0px 324px 0px;
  }
  
  .partnersHero {
    width: 1225px;
    left: 19%;
  }
    
  .partnersHeroText {
    padding: 96px 0px 0px 0px;
  }
  
  .partnersImg {
    top: 12%;
    left: 15%;
    width: 1434px;
    height: 1434px;
  }

}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --mainText: 120px/120px 'Monument Extended Ultrabold';
    --mainDescription: 36px/43px 'Neue Machina Regular';
    --button: 12px/20px 'Neue Machina Regular';
    --ourMissionTitleFirst: 64px/64px 'Monument Extended Ultrabold';
    --ourMissionDescription: 24px/29px 'Neue Machina Light';
    --expertiseTitle: 100px/120px 'Monument Extended Ultrabold';
    --expertiseDescription: 36px/43px 'Neue Machina Regular';
    --expertiseAdvantagesItemTitle: 48px/58px 'Neue Machina Regular';
    --expertiseAdvantagesItemText: 14px/17px 'Neue Machina Regular';
    --partnersHeroTitle: 120px/144px 'Monument Extended Ultrabold';
  }

  .heroContainer,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    max-width: 1280px;
  }

  .hero,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    min-height: 720px;
  }

  .heroMain {
    padding: 106px 0px 0px 0px;
  }

  .heroMainText {
    width: 768px;
  }

  .heroHeader {
    padding: 22px 0px 0px 0px;
  }

  .titlePoint {
    margin: 0px 0px 0px 32px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMainDescription {
    padding: 29px 518px 5px 241px;
  }

  .heroButton {
    height: 52px;
    padding: 0px 0px 0px 1070px;
    background-size: 10px 18px;
  }

  .ourMission {
    padding: 205px 0px 250px 0px;
    max-width: 1280px;
  }
  
  .ourMissionContainer {
    width: 1230px;
    height: 1058px;
  }
      
  @keyframes circle {
    from{
      transform: rotate(0deg) translate(-700px) rotate(0) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-700px) rotate(-360deg) scale(1.5, 1);
    }
  }

  @keyframes star {
    from{
      transform: rotate(180deg) translate(-600px) rotate(-180deg) scale(1.5, 1);
    }
    to{
      transform: rotate(540deg) translate(-600px) rotate(-540deg) scale(1.5, 1);
    }
  }
  
  @keyframes smallStar {
    from{
      transform: rotate(0deg) translate(-600px) rotate(0deg) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-600px) rotate(-360deg) scale(1.5, 1);
    }
  }
    
  .dot {
    width: 120px;
    height: 120px;
    top: 87%;
    left: 90%;
    background-size: 120px;
  }

  .star {
    width: 74px;
    height: 120px;
    top: 89%;
    left: 28%;
    background-size: 74px 120px;
  }
  
  .smallStar {
    width: 62px;
    height: 60px;
    top: -6%;
    left: 78%;
    background-size: 62px 60px;
  }
    
  .parnerCircleOrbit {
    width: 1230px;
    height: 1058px;
    top: -17%;
    left: 0%;
    padding: 150px 75px 150px 75px;
    border: 1px solid #2E2E2E;
  }

  .starOrbit {
    width: 1230px;
    height: 1058px;
    top: -9%;
    left: 0%;
    z-index: 1;
    padding: 50px 75px 50px 75px;
    border: 1px solid #2E2E2E;
  }
  
  .ourMissionImg {
    width: 800px;
    height: 800px;
    top: 10%;
    left: 22%;
  }

  .ourMissionArrow {
    width: 24px;
    height: 64px;
    top: 45%;
    left: 88%;
  }

  .ourMissionText {
    width: 641px;
    top: 24%;
    left: 25%;
  }
    
  .ourMissionSubTitle {
    padding: 48px 0px 48px 0px;
  }
    
  .expertiseTitle {
    padding: 0px 0px 64px 233px;
  }
  
  .expertiseDescription {
    height: 200px;
  }
  
  .expertiseDescriptionText {
    width: 604px;
    left: 233px;
  }
  
  .expertiseDescriptionImg {
    width: 500px;
    height: 500px;
    top: -100%;
    left: 60%;
  }
    
  .expertiseAdvantages {
    padding: 80px 0px 0px 0px;
  }

  .expertiseAdvantagesItem:nth-child(1),
  .expertiseAdvantagesItem:nth-child(3),
  .expertiseAdvantagesItem:nth-child(4) {
    width: 340px;
    height: 340px;
  }
  
  .expertiseAdvantagesItem:nth-child(2) {
    width: 69px;
    padding: 116px 135px 121px 136px;
  }
      
  .expertiseAdvantagesItemTitle,
  .perfomanceAdvantagesItemTitle  {
    padding: 0px 0px 28px 0px;
  }
    
  .expertiseAdvantagesItemGif,
  .perfomanceAdvantagesItemGif {
    height: 340px;
  }
    
  .expertiseAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -59%;
  }

  .perfomance {
    padding: 300px 0px 151px 0px;
  }
    
  .perfomanceMain {
    width: 660px;
    /* padding: 0px 387px 0px 233px; */
  }
    
  .perfomanceHeroText {
    width: 590px;
    padding: 30px 0px 0px 0px;
  }
  
  .perfomanceHeroImg {
    width: 500px;
    height: 500px;
    left: 60%;
  }
  
  .perfomanceAdvantages {
    padding: 700px 0px 0px 0px;
  }
  
  .perfomanceAdvantagesItem:nth-child(1) {
    width: 123px;
    padding: 133px 110px 104px 107px;
  }
  
  .perfomanceAdvantagesItem:nth-child(2),
  .perfomanceAdvantagesItem:nth-child(3),
  .perfomanceAdvantagesItem:nth-child(4) {
    width: 340px;
    height: 340px;
  }
    
  .perfomanceAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -60%;
  }

  .partners {
    padding: 600px 0px 316px 0px;
  }
  
  .partnersHero {
    width: 817px;
    /* padding: 0px 230px 0px 233px; */
  }
    
  .partnersHeroText {
    padding: 64px 0px 0px 0px;
  }
  
  .partnersImg {
    width: 956px;
    height: 956px;
    top: 26%;
    left: 12%;
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --mainText: 96px/96px 'Monument Extended Ultrabold';
    --mainDescription: 36px/43px 'Neue Machina Regular';
    --button: 12px/20px 'Neue Machina Regular';
    --ourMissionTitleFirst: 64px/64px 'Monument Extended Ultrabold';
    --ourMissionDescription: 24px/29px 'Neue Machina Light';
    --expertiseTitle: 96px/115px 'Monument Extended Ultrabold';
    --expertiseDescription: 24px/24px 'Neue Machina Regular';
    --expertiseAdvantagesItemTitle: 48px/58px 'Neue Machina Regular';
    --expertiseAdvantagesItemText: 14px/17px 'Neue Machina Regular';
    --partnersHeroTitle: 96px/115px 'Monument Extended Ultrabold';
  }

  .heroContainer,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    max-width: 768px;
  }

  .hero,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    min-height: 1024px;
    overflow-x: hidden;
  }

  .heroMain {
    padding: 267px 0px 0px 0px;
  }

  .heroMainText {
    width: 684px;
  }

  .heroHeader {
    padding: 22px 0px 0px 0px;
  }

  .titlePoint {
    margin: 0px 0px 0px 44px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMainDescription {
    padding: 48px 204px 154px 42px;
  }

  .heroButton {
    padding: 0px 0px 0px 578px;
    background-position: 77% 80%;
  }

  .ourMission {
    padding: 338px 0px 190px 0px;
    max-width: 768px;
  }
  
  .ourMissionText {
    width: 641px;
    top: 51%;
    left: 22%;
  }

  @keyframes star {
    from{
      transform: rotate(180deg) translate(-700px) rotate(-180deg) scale(1.5, 1);
    }
    to{
      transform: rotate(540deg) translate(-700px) rotate(-540deg) scale(1.5, 1);
    }
  }
  
  @keyframes smallStar {
    from{
      transform: rotate(0deg) translate(-700px) rotate(0deg) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-700px) rotate(-360deg) scale(1.5, 1);
    }
  }

  .dot {
    top: 96%;
    left: 100%;
  }

  .star {
    top: 99%;
    left: 10%;
  }

  .smallStar {
    top: 37%;
    left: 100%;
  }

  .parnerCircleOrbit {
    top: 6%;
  }

  .starOrbit {
    top: 15%;
    left: 0%;
  }
    
  .ourMissionSubTitle {
    padding: 48px 0px 48px 0px;
  }
    
  .ourMissionImg {
    width: 800px;
    height: 800px;
    top: 34%;
    left: 23%;
  }

  .ourMissionArrow {
    top: 69%;
  }

  .expertiseTitle {
    padding: 0px 0px 64px 68px;
  }
  
  .expertiseDescription {
    height: 200px;
  }
  
  .expertiseDescriptionText {
    width: 414px;
    left: 127px;
  }
  
  .expertiseDescriptionImg {
    width: 500px;
    height: 500px;
    top: -110%;
    left: 60%;
    overflow: hidden;
  }

  .expertiseAdvantages {
    height: 680px;
    padding: 20px 0px 0px 0px;
  }
    
  .expertiseAdvantagesItem:nth-child(1) {
    left: 10%;
  }
  
  .expertiseAdvantagesItem:nth-child(2) {
    width: 69px;
    padding: 116px 135px 121px 136px;
    left: 45%;
  }
  
  .expertiseAdvantagesItem:nth-child(3) {
    top: 39%;
    left: 10%;
  }
  
  .expertiseAdvantagesItem:nth-child(4) {
    top: 39%;
    left: 45%;
  }
  
  .expertiseAdvantagesItemTitle,
  .perfomanceAdvantagesItemTitle  {
    padding: 0px 0px 28px 0px;
  }
    
  .expertiseAdvantagesItemGif,
  .perfomanceAdvantagesItemGif {
    height: 340px;
  }
    
  .expertiseAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -59%;
  }

  .perfomance {
    padding: 154px 0px 151px 0px;
  }
  
  .perfomanceHero {
    height: 356px;
    overflow: hidden;
  }
    
  .perfomanceMain {
    width: 528px;
    /* padding: 0px 120px 0px 120px; */
  }
    
  .perfomanceHeroText {
    width: 514px;
    padding: 30px 0px 0px 0px;
  }
  
  .perfomanceHeroImg {
    width: 500px;
    height: 500px;
    top: -18%;
    left: 65%;
    overflow: hidden;
  }
  
  .perfomanceAdvantages {
    height: 600px;
    padding: 200px 0px 0px 0px;
  }
  
  .perfomanceAdvantagesItem:nth-child(1) {
    width: 123px;
    padding: 133px 110px 104px 107px;
    left: 10%;
  }
  
  .perfomanceAdvantagesItem:nth-child(2) {
    left: 45%;
  }
  
  .perfomanceAdvantagesItem:nth-child(3) {
    top: 57%;
    left: 10%;
    z-index: 1;
  }
  
  .perfomanceAdvantagesItem:nth-child(4) {
    top: 57%;
    left: 45%;
    z-index: 1;
  }
  
  .perfomanceAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -60%;
  }

  .partners {
    padding: 100px 0px 0px 0px;
    overflow: hidden;
  }
  
  .partnersHero {
    width: 654px;
    /* padding: 0px 57px 0px 57px; */
    left: 8%;
  }
    
  .partnersHeroText {
    width: 514px;
    padding: 64px 70px 0px 70px;
    font: 36px/43px 'Neue Machina Regular';
  }
  
  .partnersImg {
    width: 956px;
    height: 956px;
    top: -5%;
    left: -11%;
  }

}

/* 375px */

@media (max-width: 767px) {

  :root {
    --mainText: 48px/48px 'Monument Extended Ultrabold';
    --mainDescription: 24px/24px 'Neue Machina Regular';
    --button: 12px/20px 'Neue Machina Regular';
    --ourMissionTitleFirst: 64px/64px 'Monument Extended Ultrabold';
    --ourMissionDescription: 16px/19px 'Neue Machina Regular';
    --expertiseTitle: 48px/58px 'Monument Extended Ultrabold';
    --expertiseDescription: 20px/24px 'Neue Machina Medium';
    --expertiseAdvantagesItemTitle: 48px/58px 'Neue Machina Regular';
    --expertiseAdvantagesItemText: 14px/17px 'Neue Machina Regular';
    --partnersHeroTitle: 48px/58px 'Monument Extended Ultrabold';
  }

  .heroContainer,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    max-width: 375px;
  }

  .hero,
  .ourMission,
  .expertise,
  .perfomance,
  .partners {
    min-height: 667px;
  }

  .heroMain {
    padding: 160px 0px 0px 0px;
    justify-content: start;
    align-items: flex-start;
  }

  .heroMainText {
    width: 325px;
    padding: 0px 0px 0px 13px;
  }

  .heroHeader {
    padding: 22px 11px 0px 0px;
  }

  .titlePoint {
    display: none;
  }

  .menu {
    padding: 0px 11px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMainDescription {
    width: 340px;
    padding: 50px 11px 154px 13px;
  }

  .heroButton {
    display: none;
    padding: 0px 0px 0px 0px;
  }

  .ourMission {
    padding: 180px 0px 200px 0px;
  }
  
  .ourMissionContainer {
    width: 991px;
    height: 750px;
    max-width: 375px;
  }

  @keyframes circle {
    from{
      transform: rotate(0deg) translate(-570px) rotate(0) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-570px) rotate(-360deg) scale(1.5, 1);
    }
  }

  @keyframes star {
    from{
      transform: rotate(180deg) translate(-565px) rotate(-180deg) scale(1.5, 1);
    }
    to{
      transform: rotate(540deg) translate(-565px) rotate(-540deg) scale(1.5, 1);
    }
  }
  
  @keyframes smallStar {
    from{
      transform: rotate(0deg) translate(-565px) rotate(0deg) scale(1.5, 1);
    }
    to{
      transform: rotate(360deg) translate(-565px) rotate(-360deg) scale(1.5, 1);
    }
  }
    
  .dot {
    width: 65px;
    height: 65px;
    top: 66%;
    left: 194%;
    background-size: 65px;
  }

  .star {
    width: 52px;
    height: 84px;
    top: 65%;
    left: -89%;
    background-size: 52px 84px;
  }
  
  .smallStar {
    width: 43px;
    height: 42px;
    top: 25%;
    left: 194%;
    background-size: 43px 42px;
  }
    
  .parnerCircleOrbit {
    width: 991px;
    height: 750px;
    top: -22%;
    left: -94%;
    padding: 150px 75px 150px 75px;
  }

  .starOrbit {
    width: 991px;
    height: 750px;
    top: -11%;
    left: -94%;
    padding: 75px 75px 75px 75px;
  }
  
  .ourMissionText {
    width: 323px;
    top: 23%;
    left: 10%;
  }

  .ourMissionTitleSecond {
    font: 64px/64px 'Monument Extended Ultrabold';
  }
    
  .ourMissionSubTitle {
    font: 24px/24px 'Neue Machina Medium';
  }
    
  .ourMissionImg {
    width: 600px;
    height: 600px;
    top: 8%;
    left: -25%;
  }

  .ourMissionArrow {
    top: 44%;
    left: 145%;
  }

  .expertiseTitle {
    padding: 0px 0px 48px 12px;
  }
  
  .expertiseDescription {
    height: 50px;
  }
  
  .expertiseDescriptionText {
    width: 258px;
    left: 106px;
  }
  
  .expertiseDescriptionImg {
    width: 500px;
    height: 500px;
    top: 0%;
    left: -17%;
  }

  .expertiseAdvantages {
    height: 1700px;
  }
      
  .expertiseAdvantagesItem:nth-child(1) {
    top: 35%;
    left: 5%;
  }
  
  .expertiseAdvantagesItem:nth-child(2) {
    width: 69px;
    padding: 116px 135px 121px 136px;
    top: 65%;
    left: 2%;
  }
  
  .expertiseAdvantagesItem:nth-child(3) {
    top: 20%;
    left: 5%;
  }
  
  .expertiseAdvantagesItem:nth-child(4) {
    top: 50%;
    left: 5%;
  }
  
  .expertiseAdvantagesItemTitle,
  .perfomanceAdvantagesItemTitle  {
    padding: 0px 0px 28px 0px;
  }
    
  .expertiseAdvantagesItemGif,
  .perfomanceAdvantagesItemGif {
    height: 340px;
  }
    
  .expertiseAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -59%;
    left: -285%;
  }

  .perfomance {
    padding: 0px 0px 0px 0px;
  }
  
  .perfomanceHero {
    height: 600px;
    overflow: hidden;
  }
    
  .perfomanceMain {
    width: 352px;
    left: 4%;
  }

  .perfomanceHeroTitle {
    font: var(--expertiseTitle);
  }
    
  .perfomanceHeroText {
    width: 256px;
    padding: 30px 0px 0px 96px;
  }
  
  .perfomanceHeroImg {
    width: 400px;
    height: 400px;
    top: 40%;
    left: 0%;
    overflow: hidden;
  }
  
  .perfomanceAdvantages {
    height: 1300px;
    padding: 100px 0px 0px 0px;
  }
  
  .perfomanceAdvantagesItem:nth-child(1) {
    width: 123px;
    padding: 133px 110px 104px 107px;
    top: 0%;
    left: 5%;
  }
  
  .perfomanceAdvantagesItem:nth-child(2) {
    top: 18%;
    left: 5%;
  }
  
  .perfomanceAdvantagesItem:nth-child(3) {
    top: 36%;
    left: 5%;
  }
  
  .perfomanceAdvantagesItem:nth-child(4) {
    top: 54%;
    left: 5%;
  }
  
  .perfomanceAdvantagesItemGifImg {
    width: 480px;
    height: 480px;
    top: -60%;
  }

  .partners {
    padding: 100px 0px 0px 0px;
  }
  
  .partnersHero {
    width: 344px;
    left: 4%;
  }
    
  .partnersHeroText {
    width: 256px;
    padding: 64px 0px 0px 85px;
    font: var(--expertiseDescription);
  }
  
  .partnersImg {
    width: 700px;
    height: 700px;
    top: -5%;
    left: -35%;
  }

}


