/* 3840px */

:root {
  --heroTitle: 602px/602px 'Monument Extended Ultrabold';
  --heroDescriptionText: 72px/87px 'Neue Machina Light';
  --ourServicesTitle: 72px/72px 'Neue Machina Medium';
  --ourServicesContentTitle: 48px/58px 'Neue Machina Regular';
  --ourServicesContentText: 40px/50px 'Neue Machina Regular';
  --ourServicesFooterText: 72px/87px 'Neue Machina Light';
  --ourServicesFooterTitle: 193px/193px 'Monument Extended Ultrabold';
  --ourText: 185px/185px 'Monument Extended Ultrabold';
  --projectsText: 370px/370px 'Monument Extended Ultrabold';
  --ourProjectsAccordionTitle: 72px/86px 'Neue Machina Regular';
  --accordrionContent: 48px/48px 'Neue Machina Medium';
  --ourProjectsAccordionLink: 28px/32px 'Neue Machina Regular';
}

.services {
  position: relative;
}

.servicesMenu {
  position: fixed;
  width: 100%;
  z-index: 4;
}

.hero {
  min-height: 4000px;
  height: 100vh;
  overflow-x: hidden;
}

.hero {
  padding: 0px 0px 100px 0px;
}

.heroHeader {
  position: fixed;
  width: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 87px 0px 0px 0px;
  pointer-events: none;
}

.titlePoint {
  width: 16px;
  height: 16px;
  margin: 0px 24px 0px 96px;
  border-radius: 50%;
  background-color: #D2FC04;
}

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 78px 0px 0px;
  pointer-events: all;
}

.menu p {
  margin: 0px 114px 0px 0px;
}

.heroMainContainer {
  background-color: #070606;
}

.heroMain {
  position: relative;
  height: 2650px;
  max-width: 3840px;
  margin: 0 auto;
}

.heroTitle {
  position: absolute;
  width: 2268px;
  z-index: 2;
  top: 12%;
  left: 20%;
  font: var(--heroTitle);
  color: #FFFFFF;
}

.heroArrow {
  position: absolute;
  width: 72px;
  height: 193px;
  top: 83%;
  left: 50%;
  z-index: 2;
}

.heroImg {
  position: absolute;
  width: 3854px;
  height: 2670px;
  top: -8%;
  z-index: 1;
}

.heroDescription {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 85px 0px 0px 0px;
}

.heroDescriptionText {
  text-align: center;
  width: 1818px;
  font: var(--heroDescriptionText);
  color: #FFFFFF;
}

.heroPoliceLine,
.heroCapitalLine {
  width: 100%;
  height: 167px;
}

.heroPoliceLine {
  padding: 248px 0px 157px 0px;
  background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
  background-size: 3840px 167px;
  background-position: center;
  -webkit-background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
  -webkit-background-size: 3840px 167px;
  -webkit-background-position: center;
  /* -webkit-animation: firstLine 1s infinite;
          animation: firstLine 1s infinite; */
}

@-webkit-keyframes firstLine {
  0% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
  50% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
  100% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
}

@keyframes firstLine {
  0% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
  50% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
  100% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position: center;
  }
}

.heroCapitalLine {
  background: repeat-x url('../../../public/content/services/capital-police-line.svg');
  background-size: 3840px 167px;
  background-position-x: -250px;
  /* -webkit-animation: secondLine 2s infinite;
          animation: secondLine 2s infinite; */
}

@-webkit-keyframes secondLine {
  0% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
  50% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
  100% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
}

@keyframes secondLine {
  0% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
  50% {
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
  100% {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 3840px 167px;
    background-position-x: -250px;
  }
}

.ourServicesMain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.ourServicesMainTitle {
  padding: 0px 0px 272px 0px;
  font: var(--ourServicesTitle);
  color: #FFFFFF;
}

.ourServicesMainLeftImg {
  width: 482px;
  height: 765px;
}

.ourServicesItem {
  width: 2131px;
  border-top: 3px solid #2E2E2E;
  border-bottom: 3px solid #2E2E2E;
}

.ourServicesItemTitle {
  font: var(--ourServicesTitle);
  color: #FFFFFF;
}

.ourProjects {
  display: none;
}

.ourServicesGif {
  width: 1505px;
  height: 1141px;
  margin: 500px auto 388px auto;
}

.ourServicesFooter {
  position: relative;
  padding: 0px 0px 411px 0px;
  text-align: center;
}

.ourServicesFooterText {
  width: 2394px;
  margin: 0 auto 170px auto;
  font: var(--ourServicesFooterText);
  color: #FFFFFF;
}

.ourServicesFooterTitle {
  width: 2380px;
  margin: 0 auto;
  font: var(--ourServicesFooterTitle);
  color: #FFFFFF;
}

.ourSevicesFooterCircleImg {
  position: absolute;
  width: 319px;
  height: 319px;
  top: 85%;
  left: 47%;
  -webkit-animation: rotate 15s infinite linear;
          animation: rotate 15s infinite linear;
}

@-webkit-keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes rotate {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.accordionList {
  width: 2130px;
  margin: -100px 0px 0px 0px;
}

.accordionContent::-webkit-scrollbar { 
  width: 0; 
}

.accordion {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
}

.accordionSection {
  display: flex;
  flex-direction: column;
}

.accordionTitle {
  padding: 97px 0px 120px 0px;
  font: var(--ourServicesTitle);
  color: #FFFFFF;
}

.accordionItem {
  border-top: 3px solid #2E2E2E;
}

.accordionIcon {
  background: center no-repeat url('../../../public/content/general/accordion-arrow.svg');
  background-size: 72px;
  width: 72px;
  height: 72px;
  margin: 0 20px 0 auto;
  transform: rotate(180deg);
  transition: transform 0.6s ease-in-out;
}

.rotate {
  transform: rotate(0deg);
}

.accordionContent {
  overflow: auto;
  transition: max-height 0.6s ease-in-out;
}

.accordionText {
  padding: 0px 0px 108px 0px;
  color: #888888;
}

.accordionTextTitle {
  font: var(--ourServicesContentTitle);
}

.accordionTextContent {
  font: var(--ourServicesContentText);
}

.ourProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 300px 0px 0px 0px;
}

.ourProjectsImg {
  width: 380px;
  height: 620px;
}

.ourText {
  padding: 170px 0px 0px 0px;
  font: var(--ourText);
  color: #FFFFFF;
}

.projectsText {
  padding: 0px 0px 170px 0px;
  font: var(--projectsText);
  color: #FFFFFF;
}

.ourProjectsAccordionList {
  width: 2107px;
}
    
.ourProjectsAccordionTitle {
  padding: 97px 0px 120px 0px;
  font: var(--ourProjectsAccordionTitle);
  color: #888888;
  transition: color 0.6s ease;
}
  
.ourProjectsAccordionIcon {
  background-size: 120px;
  width: 120px;
  height: 120px;
  transform: rotate(0deg);
  transition: transform 0.6s ease-in-out;
}

.ourProjectsAccordionIcon circle {
  fill:#171615;
  transition: fill 0.3s ease-in-out;
}

.ourProjectsAccordionIcon path {
  fill: #FFFFFF;
  transition: fill 0.3s ease-in-out;
}

.ourProjectsAccordionIcon:hover circle {
  fill:#D2FC04;
  transition: fill 0.3s ease-in-out;
}

.ourProjectsAccordionIcon:hover path {
  fill: #1A1A1A;
  transition: fill 0.3s ease-in-out;
}

.ourProjectsRotate {
  transform: rotate(-45deg);
}
  
.ourProjectsAccordionText {
  padding: 0px 0px 64px 0px;
  font: var(--accordrionContent);
  color: #888888;
  transition: color 0.6s ease-in-out;
}

.active {
  color: #FFFFFF;
  transition: color 0.5s ease-in-out;
}

.ourProjectsAccordionLink {
  width: 300px;
  padding: 0px 0px 12px 0px;
  background: no-repeat url('../../../public/content/general/link-arrow.svg');
  background-position: 100% 20%;
  background-size: 20px;
  border-bottom: 3px solid #D2FC04;
  font: var(--ourProjectsAccordionLink);
  color: #D2FC04;
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --heroTitle: 300px/300px 'Monument Extended Ultrabold';
    --heroDescriptionText: 36px/43px 'Neue Machina Light';
    --ourServicesTitle: 36px/36px 'Neue Machina Medium';
    --ourServicesContentTitle: 24px/29px 'Neue Machina Regular';
    --ourServicesContentText: 20px/25px 'Neue Machina Regular';
    --ourServicesFooterText: 36px/43px 'Neue Machina Light';
    --ourServicesFooterTitle: 96px/96px 'Monument Extended Ultrabold';
    --ourText: 90px/90px 'Monument Extended Ultrabold';
    --projectsText: 180px/180px 'Monument Extended Ultrabold';
    --ourProjectsAccordionTitle: 36px/43px 'Neue Machina Regular';
    --accordrionContent: 24px/24px 'Neue Machina Medium';
    --ourProjectsAccordionLink: 14px/17px 'Neue Machina Regular';
  }

  .hero {
    min-height: 2300px;
    padding: 0px 0px 84px 0px;
  }

  .heroHeader {
    padding: 44px 0px 0px 0px;
  }

  .titlePoint {
    width: 8px;
    height: 8px;
    margin: 0px 12px 0px 48px;
  }

  .menu {
    padding: 0px 33px 0px 0px;
  }

  .menu p {
    margin: 0px 33px 0px 0px;
  }

  .heroMain {
    height: 1440px;
    max-width: 1920px;
  }

  .heroTitle {
    width: 1130px;
    top: 12%;
    left: 20%;
  }

  .heroArrow {
    width: 35px;
    height: 83px;
    top: 83%;
    left: 50%;
  }

  .heroImg {
    width: 1920px;
    height: 1440px;
    top: -8%;
  }

  .heroDescription {
    padding: 42px 0px 0px 0px;
  }

  .heroDescriptionText {
    width: 906px;
  }

  .heroPoliceLine,
  .heroCapitalLine {
    height: 84px;
  }

  .heroPoliceLine {
    padding: 125px 0px 78px 0px;  
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 1920px 84px;
    background-position: center;
    /* -webkit-animation: firstLine 1s infinite;
            animation: firstLine 1s infinite; */
  }

  @-webkit-keyframes firstLine {
    0% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
    50% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
    100% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
  }

  @keyframes firstLine {
    0% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
    50% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
    100% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position: center;
    }
  }

  .heroCapitalLine {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 1920px 84px;
    background-position-x: -150px;
    /* -webkit-animation: secondLine 2s infinite;
            animation: secondLine 2s infinite; */
  }

  @-webkit-keyframes secondLine {
    0% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
    50% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
    100% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
  }

  @keyframes secondLine {
    0% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
    50% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
    100% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1920px 84px;
      background-position-x: -150px;
    }
  }

  .ourServicesMainTitle {
    padding: 0px 0px 135px 0px;
  }

  .ourServicesMainLeftImg {
    width: 245px;
    height: 381px;
  }

  .ourServicesItem {
    width: 1062px;
    border-top: 1.5px solid #2E2E2E;
    border-bottom: 1.5px solid #2E2E2E;
  }

  .ourServicesGif {
    width: 750px;
    height: 569px;
    margin: 268px auto 194px auto;
  }

  .ourServicesFooter {
    padding: 0px 0px 206px 0px;
  }

  .ourServicesFooterText {
    width: 1193px;
    margin: 0 auto 85px auto;
  }

  .ourServicesFooterTitle {
    width: 1184px;
  }

  .ourSevicesFooterCircleImg {
    width: 159px;
    height: 159px;
    top: 85%;
  }

  .accordionList {
    width: 1062px;
    margin: -50px 0px 0px 0px;
  }
    
  .accordionTitle {
    padding: 48px 0px 60px 0px;
  }
  
  .accordionItem {
    border-top: 1.5px solid #2E2E2E;
  }
  
  .accordionIcon {
    background-size: 36px;
    width: 36px;
    height: 36px;
  }
      
  .accordionText {
    padding: 0px 0px 54px 0px;
  }
  
  .ourProjectsImg {
    width: 200px;
    height: 325px;
  }
  
  .ourText {
    padding: 170px 0px 0px 0px;
  }
  
  .projectsText {
    padding: 0px 0px 170px 0px;
  }
  
  .ourProjectsAccordionList {
    width: 1050px;
  }
      
  .ourProjectsAccordionTitle {
    padding: 48px 0px 50px 0px;
  }
    
  .ourProjectsAccordionIcon {
    background-size: 60px;
    width: 60px;
    height: 60px;
  }
    
  .ourProjectsAccordionText {
    padding: 0px 0px 60px 0px;
  }
    
  .ourProjectsAccordionLink {
    width: 150px;
    padding: 0px 0px 12px 0px;
    background: no-repeat url('../../../public/content/general/link-arrow.svg');
    background-position: 100% 20%;
    background-size: 11px;
    border-bottom: 1.5px solid #D2FC04;
    font: var(--ourProjectsAccordionLink);
    color: #D2FC04;
  }
  
}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --heroTitle: 200px/200px 'Monument Extended Ultrabold';
    --heroDescriptionText: 24px/29px 'Neue Machina Light';
    --ourServicesTitle: 24px/24px 'Neue Machina Medium';
    --ourServicesContent: 24px/29px 'Neue Machina Regular';
    --ourServicesContentTitle: 16px/19px 'Neue Machina Regular';
    --ourServicesContentText: 14px/17px 'Neue Machina Regular';
    --ourText: 64px/64px 'Monument Extended Ultrabold';
    --projectsText: 120px/120px 'Monument Extended Ultrabold';
    --ourProjectsAccordionTitle: 36px/43px 'Neue Machina Regular';
    --ourProjectsAccordionText: 24px/24px 'Neue Machina Medium';
    --ourProjectsAccordionLink: 14px/17px 'Neue Machina Regular';
    --ourServicesFooterText: 24px/29px 'Neue Machina Light';
    --ourServicesFooterTitle: 64px/64px 'Monument Extended Ultrabold';
  }

  .hero {
    min-height: 1500px;
    padding: 0px 0px 84px 0px;
  }

  .heroHeader {
    padding: 22px 0px 0px 0px;
  }

  .titlePoint {
    margin: 0px 8px 0px 32px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroContainer {
    max-width: 1280px;
    overflow-x: hidden;
    margin: 0 auto;
  }

  .heroMain {
    height: 960px;
    max-width: 1280px;
  }

  .heroTitle {
    width: 754px;
    top: 12%;
    left: 20%;
  }

  .heroArrow {
    width: 23px;
    height: 56px;
    top: 83%;
    left: 50%;
  }

  .heroImg {
    width: 1280px;
    height: 960px;
    top: -8%;
  }

  .heroDescription {
    padding: 28px 0px 0px 0px;
  }

  .heroDescriptionText {
    width: 604px;
  }

  .heroPoliceLine,
  .heroCapitalLine {
    height: 56px;
  }

  .heroPoliceLine {
    padding: 82px 0px 52px 0px;
    background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
    background-size: 1280px 56px;
    background-position: center;
    /* -webkit-animation: firstLine 1s infinite;
            animation: firstLine 1s infinite; */
  }

  @-webkit-keyframes firstLine {
    0% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
    50% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
    100% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
  }

  @keyframes firstLine {
    0% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
    50% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
    100% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position: center;
    }
  }

  .heroCapitalLine {
    background: repeat-x url('../../../public/content/services/capital-police-line.svg');
    background-size: 1280px 56px;
    background-position-x: -75px;
    /* -webkit-animation: secondLine 2s infinite;
            animation: secondLine 2s infinite; */
  }

  @-webkit-keyframes secondLine {
    0% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
    50% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
    100% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
  }

  @keyframes secondLine {
    0% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
    50% {
      background: repeat-x url('../../../public/content/services/alphabet-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
    100% {
      background: repeat-x url('../../../public/content/services/capital-police-line.svg');
      background-size: 1280px 56px;
      background-position-x: -75px;
    }
  }

  .ourServicesMainTitle {
    padding: 0px 0px 90px 0px;
  }

  .ourServicesMainLeftImg {
    width: 161px;
    height: 254px;
  }

  .ourServicesItem {
    width: 708px;
    border-top: 1px solid #2E2E2E;
    border-bottom: 1px solid #2E2E2E;
  }

  .ourProjects {
    padding: 300px 0px 0px 0px;
  }

  .ourProjectsImg {
    width: 120px;
    height: 194px;
  }

  .ourText {
    padding: 170px 0px 0px 0px;
  }

  .projectsText {
    padding: 0px 0px 170px 0px;
  }

  .ourProjectsAccordionList {
    width: 700px;
  }
      
  .ourProjectsAccordionTitle {
    padding: 32px 0px 44px 0px;
  }
    
  .ourProjectsAccordionIcon {
    background-size: 40px;
    width: 40px;
    height: 40px;
  }
      
  .ourProjectsAccordionText {
    padding: 0px 0px 64px 0px;
  }
    
  .ourProjectsAccordionLink {
    width: 150px;
    padding: 0px 0px 4px 0px;
    background: no-repeat url('../../../public/content/general/link-arrow.svg');
    background-position: 100% 30%;
    background-size: 9px;
    border-bottom: 1px solid #D2FC04;
  }

  .ourServicesGif {
    width: 500px;
    height: 379px;
    margin: 140px auto 81px auto;
  }

  .ourServicesFooter {
    padding: 0px 0px 206px 0px;
  }

  .ourServicesFooterText {
    width: 795px;
    margin: 0 auto 56px auto;
  }

  .ourServicesFooterTitle {
    width: 790px;
  }

  .ourSevicesFooterCircleImg {
    width: 106px;
    height: 106px;
    top: 91%;
    left: 48%;
  }

  .accordionList {
    width: 708px;
    margin: -40px 0px 0px 0px;
  }
    
  .accordionTitle {
    padding: 32px 0px 40px 0px;
  }
  
  .accordionItem {
    border-top: 1px solid #2E2E2E;
  }
  
  .accordionIcon {
    width: 24px;
    height: 24px;
    background-size: 24px;
    margin: 0 5px 15px auto;
  }
      
  .accordionText {
    padding: 0px 0px 36px 0px;
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --heroTitle: 180px/180px 'Monument Extended Ultrabold';
    --heroDescriptionText: 24px/29px 'Neue Machina Light';
    --ourServicesTitle: 24px/24px 'Neue Machina Regular';
    --ourServicesListTitle: 24px/24px 'Neue Machina Regular';
    --ourServicesContent: 16px/19px 'Neue Machina Regular';
    --ourServicesFooterText: 24px/29px 'Neue Machina Light';
    --ourServicesFooterTitle: 64px/64px 'Monument Extended Ultrabold';
  }

  .hero {
    min-height: 1500px;
    padding: 0px 0px 84px 0px;
  }

  .heroHeader {
    padding: 22px 0px 0px 0px;
  }

  .titlePoint {
    margin: 0px 8px 0px 44px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMain {
    height: 900px;
    max-width: 768px;
  }

  .heroTitle {
    width: 678px;
    top: 12%;
    left: 6%;
  }

  .heroArrow {
    width: 23px;
    height: 56px;
    top: 85%;
    left: 50%;
  }

  .heroImg {
    width: 1280px;
    height: 930px;
    left: -30%;
    top: -8%;
  }

  .heroDescriptionText {
    width: 604px;
  }

  .ourServicesMain {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 514px;
    margin: 0 auto;
  }

  .ourServicesMainLeft {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0px 0px 129px 0px;
  }

  .ourServicesMainTitle {
    padding: 0px 0px 0px 0px;
  }

  .ourServicesMainLeftImg {
    width: 157px;
    height: 248px;
  }

  .ourServicesItem {
    width: 514px;
    border-top: 1px solid #2E2E2E;
    border-bottom: 1px solid #2E2E2E;
  }

  .ourProjects {
    padding: 120px 0px 100px 0px;
  }

  .ourProjectsAccordionList {
    width: 700px;
  }

  .ourText {
    padding: 100px 0px 0px 0px;
  }

  .projectsText {
    padding: 0px 0px 100px 0px;
  }
      
  .ourProjectsAccordionTitle {
    padding: 32px 0px 44px 0px;
  }
    
  .ourProjectsAccordionIcon {
    background-size: 40px;
    width: 40px;
    height: 40px;
  }
      
  .ourProjectsAccordionText {
    padding: 0px 0px 32px 0px;
  }
    
  .ourProjectsAccordionLink {
    width: 150px;
    padding: 0px 0px 4px 0px;
    background: no-repeat url('../../../public/content/general/link-arrow.svg');
    background-position: 100% 30%;
    background-size: 9px;
    border-bottom: 1px solid #D2FC04;
  }

  .ourServicesGif {
    width: 500px;
    height: 379px;
    margin: 74px auto 36px auto;
  }

  .ourServicesFooter {
    padding: 0px 0px 206px 0px;
  }

  .ourServicesFooterText {
    width: 514px;
    margin: 0 auto 36px auto;
  }

  .ourServicesFooterTitle {
    width: 694px;
  }

  .ourSevicesFooterCircleImg {
    width: 106px;
    height: 106px;
    top: 90%;
    left: 45%;
  }

  .accordionList {
    width: 514px;
    margin: 0px 0px 0px 0px;
  }
    
  .accordionTitle {
    padding: 32px 0px 40px 0px;
  }
          
}

/* 375px */

@media (max-width: 767px) {

  :root {
    --heroTitle: 96px/96px 'Monument Extended Ultrabold';
    --heroDescriptionText: 16px/19px 'Neue Machina Regular';
    --ourServicesTitle: 24px/27px 'Neue Machina Regular';
    --ourServicesListTitle: 24px/24px 'Neue Machina Regular';
    --ourServicesContent: 16px/19px 'Neue Machina Regular';
    --ourServicesFooterText: 16px/19px 'Neue Machina Regular';
    --ourServicesFooterTitle: 48px/48px 'Monument Extended Ultrabold';
    --ourText: 32px/32px 'Monument Extended Ultrabold';
    --projectsText: 60px/60px 'Monument Extended Ultrabold';
  }

  .hero {
    min-height: 1200px;
    padding: 0px 0px 84px 0px;
  }

  .heroHeader {
    padding: 29px 11px 0px 0px;
  }

  .titlePoint {
    display: none;
  }

  .heroHeader {
    padding: 22px 11px 0px 0px;
  }

  .menu {
    padding: 0px 11px 0px 0px;
  }

  .menu p {
    margin: 0px 22px 0px 0px;
  }

  .heroMain {
    height: 680px;
    max-width: 375px;
  }

  .heroTitle {
    width: 362px;
    top: 29%;
    left: 2%;
  }

  .heroArrow {
    top: 84%;
    left: 50%;
  }

  .heroImg {
    width: 890px;
    height: 667px;
    left: -65%;
    top: 3%;
  }

  .heroDescription {
    padding: 46px 0px 0px 0px;
  }

  .heroDescriptionText {
    width: 352px;
  }

  .heroPoliceLine {
    padding: 56px 0px 52px 0px;
  }

  .ourServicesMain {
    width: 350px;
    padding: 0px 11px 0px 14px;
  }

  .ourServicesMainLeft {
    padding: 0px 0px 27px 0px;
  }

  .ourServicesMainTitle {
    padding: 0px 0px 0px 0px;
  }

  .ourServicesMainLeftImg {
    width: 193px;
    height: 256px;
  }

  .ourServicesItem {
    width: 344px;
  }

  .ourServicesGif {
    width: 374px;
    height: 283px;
    margin: 113px auto 191px auto;
  }

  .ourServicesFooter {
    padding: 0px 0px 100px 0px;
  }

  .ourServicesFooterText {
    width: 352px;
    margin: 0 auto 76px auto;
  }

  .ourServicesFooterTitle {
    width: 352px;
  }

  .ourSevicesFooterCircleImg {
    width: 106px;
    height: 106px;
    top: 89%;
    left: 40%;
  }

  .accordionList {
    width: 350px;
  }
    
  .accordionTitle {
    text-align: left;
    padding: 32px 0px 40px 0px;
  }

  .accordionIcon {
    margin: 0 5px 10px auto;
  }

  .ourProjects {
    padding: 120px 0px 0px 0px;
  }

  .ourProjectsAccordionList {
    width: 351px;
  }

  .ourText {
    padding: 100px 0px 0px 0px;
  }

  .projectsText {
    padding: 0px 0px 100px 0px;
  }
      
  .ourProjectsAccordionTitle {
    padding: 32px 0px 44px 0px;
    text-align: left;
  }
    
  .ourProjectsAccordionIcon {
    background-size: 40px;
    width: 40px;
    height: 40px;
  }
      
  .ourProjectsAccordionText {
    padding: 0px 0px 32px 0px;
  }
    
  .ourProjectsAccordionLink {
    width: 150px;
    padding: 0px 0px 4px 0px;
    background: no-repeat url('../../../public/content/general/link-arrow.svg');
    background-position: 100% 30%;
    background-size: 9px;
    border-bottom: 1px solid #D2FC04;
  }
}
