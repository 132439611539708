@font-face {
  font-family: 'Neue Machina Ultrabold';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../public/fonts/NeueMachina/NeueMachina-Ultrabold.woff2') format('woff2');
}

@font-face {
  font-family: 'Neue Machina Medium';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('../public/fonts/NeueMachina/NeueMachina-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Neue Machina Regular';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('../public/fonts/NeueMachina/NeueMachina-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Neue Machina Light';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url('../public/fonts/NeueMachina/NeueMachina-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Syne Bold';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('../public/fonts/Syne/Syne-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Monument Extended Ultrabold';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url('../public/fonts/MonumentExtended/MonumentExtended-Ultrabold.woff') format('woff');
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  background-color: #000000;
  overscroll-behavior-y: none;
}

body::-webkit-scrollbar {
  width: 0; 
}

ul {
  list-style-type: none;
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 3840px */

:root {
  --title: 24px/29px 'Neue Machina Ultrabold';
  --talk: 24px/29px 'Neue Machina Regular'; 
}

.title__container {
  display: flex;
  align-items: center;
}

.title {
  padding: 0px 0px 0px 16px;
  font: var(--title);
  text-transform: uppercase;
  letter-spacing: 0.4em;
  color: #FFFFFF;
}

.talk {
  padding: 0px 0px 24px 0px;
  font: var(--talk);
  text-transform: uppercase;
  color: #F2F2F2;
  border-bottom: 3px solid #F2F2F2;
}

/* burger */

.header-burger {
  display: block;
  position: relative;
  width: 24px;
  height: 16px;
  left: 0%;
  z-index: 1;
  padding: 31px 28px 31px 28px;
  border-radius: 50%;
  transition: 0.3s ease;
  background-color: #171615;
  cursor: pointer;
}

.header-burger span {
  position: absolute;
  background-color: #DFDFDF;
  left: 0;
  width: 40%;
  height: 5px;
  top: 45%;
  left: 33%;
  transition: 0.3s ease;
}

.header-burger:before,
.header-burger:after {
  content: '';
  background-color: #DFDFDF;
  position: absolute;
  width: 20%;
  height: 5px;
  transition: 0.3s ease;
}

.header-burger:before {
  top: 25%;
  left: 53%;
}

.header-burger:after {
  top: 65%;
  left: 33%;
}

/* hover burger*/

.header-burger:hover {
  background-color: #DFDFDF;
}

.header-burger:hover span {
  background-color: #171615;
  transition: 0.3s ease;
}

.header-burger:hover:before,
.header-burger:hover:after {
  content: '';
  background-color: #171615;
  position: absolute;
  width: 20%;
  height: 5px;
  transition: 0.3s ease;
}

.header-burger:hover:before {
  top: 25%;
  left: 33%;
}

.header-burger:hover:after {
  top: 65%;
  left: 53%;
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --title: 12px/14px 'Neue Machina Ultrabold';
    --talk: 14px/17px 'Neue Machina Regular'; 
  }

  .title {
    padding: 0px 0px 0px 8px;
  }

  .talk {
    padding: 0px 0px 12px 0px;
    border-bottom: 1.5px solid #F2F2F2;
  }

  /* burger */

  .header-burger {
    width: 25px;
    height: 20px;
    padding: 15px 14px 15px 14px;
  }

  .header-burger span {
    height: 2px;
  }

  .header-burger:before,
  .header-burger:after {
    height: 2px;
  }

  /* hover burger*/

  .header-burger:hover:before,
  .header-burger:hover:after {
    height: 2px;
  }

}

/* 1280px */
@media (max-width: 1919px) {

  :root {
    --title: 12px/14px 'Neue Machina Ultrabold';
    --talk: 14px/17px 'Neue Machina Regular'; 
  }

  .talk {
    padding: 0px 0px 8px 0px;
    border-bottom: 1px solid #F2F2F2;
  }

  /* burger */

  .header-burger {
    width: 10px;
    height: 6px;
    padding: 15px 14px 15px 14px;
  }
  
  .header-burger span {
    height: 2px;
  }

  .header-burger:before,
  .header-burger:after {
    height: 2px;
  }
  
  /* hover burger*/

  .header-burger:hover:before,
  .header-burger:hover:after {
    height: 2px;
  }
  
}

/* 768px */
@media (max-width: 1279px) {

  :root {
    --title: 12px/14px 'Neue Machina Ultrabold';
    --talk: 14px/17px 'Neue Machina Regular'; 
  }

  .talk {
    padding: 0px 0px 8px 0px;
    border-bottom: 1px solid #F2F2F2;
  }
  
}


/* 367px */

@media (max-width: 767px) {

  .title {
    opacity: 0;
  }

}
