/* 3840px */

:root {
  --heroFooterText: 36px/43px 'Neue Machina Ultrabold';
  --contacts: 42px/51px 'Neue Machina Regular';
}

.footer {
  min-height: 1760px;
  background-color: #171615;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 355px 0px 0px 0px;
}

.footerHero {
  padding: 0px 388px 0px 388px;
  text-align: right;
}

.footerHeroText {
  padding: 0px 0px 162px 0px;
  text-transform: uppercase;
  font: var(--heroFooterText);
  letter-spacing: 0.4em;
  color: #888888;
}

.footerHeroTitle {
  width: 2140px;
  height: 780px;
}

.footerGetInTouchTitle,
.footerExploreTitle {
  text-transform: uppercase;
  font: var(--heroFooterText);
  letter-spacing: 0.4em;
  color: #FFFFFF;
}

.footerGetInTouchListItem,
.footerExploreListItem {
  font: var(--contacts);
  color: #888888;
}

.footerGetInTouchListItem:first-child {
  margin: 74px 0px 107px 0px;
}

.footerGetInTouchListItem:nth-child(2) {
  margin: 0px 0px 36px 0px;
}

.footerGetInTouchMailLink,
.footerGetInTouchPhoneLink,
.footerGetInTouchPhoneLink,
.footerExploreLink,
.footerAddress {
  padding: 0px 0px 12px 0px;
  border-bottom: 3px solid #888888;
}

.footerExplore {
  padding: 188px 0px 0px 0px;
}

.footerExploreList {
  padding: 50px 0px 0px 0px;
  display: flex;
}

.footerExploreListItem:nth-child(1) {
  margin: 0px 81px 0px 0px;
}

.footerExploreListItem:nth-child(2) {
  margin: 0px 75px 0px 0px;
}

.footerAddressText {
  width: 753px;
  padding: 95px 0px 36px 0px;
  font: var(--contacts);
  color: #4E4E4E;
}

.footerAddress {
  width: 320px;
  background: no-repeat url('../../../public/content/footer/footer-arrow.svg');
  background-size: 25px;
  background-position: right 30%;
  text-transform: uppercase;
  font: var(--contacts);
  color: #888888;
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --heroFooterText: 18px/22px 'Neue Machina Ultrabold';
    --contacts: 16px/19px 'Neue Machina Regular';
  }

  .footer {
    min-height: 876px;
  }

  .footerContainer {
    padding: 176px 0px 0px 0px;
  }

  .footerHero {
    padding: 0px 185px 0px 194px;
  }

  .footerHeroText {
    padding: 0px 0px 81px 0px;
  }

  .footerHeroTitle {
    width: 1066px;
    height: 389px;
  }

  .footerGetInTouchListItem:first-child {
    margin: 36px 0px 54px 0px;
  }

  .footerGetInTouchListItem:nth-child(2) {
    margin: 0px 0px 18px 0px;
  }

  .footerGetInTouchMailLink,
  .footerGetInTouchPhoneLink,
  .footerGetInTouchPhoneLink,
  .footerExploreLink,
  .footerAddress {
    padding: 0px 0px 6px 0px;
    border-bottom: 1.5px solid #888888;
  }

  .footerExplore {
    padding: 94px 0px 0px 0px;
  }

  .footerExploreList {
    padding: 24px 0px 0px 0px;
  }

  .footerExploreListItem:nth-child(1) {
    margin: 0px 41px 0px 0px;
  }

  .footerExploreListItem:nth-child(2) {
    margin: 0px 38px 0px 0px;
  }

  .footerAddressText {
    width: 375px;
    padding: 48px 0px 18px 0px;
  }

  .footerAddress {
    width: 120px;
    background-position: right 20%;
    background-size: 12px;
  }

}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --heroFooterText: 12px/14px 'Neue Machina Ultrabold';
    --contacts: 16px/19px 'Neue Machina Regular';
  }

  .footer {
    min-height: 584px;
  }

  .footerContainer {
    padding: 118px 0px 0px 0px;
  }

  .footerHero {
    padding: 0px 123px 0px 129px;
  }

  .footerHeroText {
    padding: 0px 0px 54px 0px;
  }

  .footerHeroTitle {
    width: 711px;
    height: 259px;
  }

  .footerGetInTouchListItem:first-child {
    margin: 24px 0px 36px 0px;
  }

  .footerGetInTouchListItem:nth-child(2) {
    margin: 0px 0px 12px 0px;
  }

  .footerGetInTouchMailLink,
  .footerGetInTouchPhoneLink,
  .footerGetInTouchPhoneLink,
  .footerExploreLink,
  .footerAddress {
    padding: 0px 0px 4px 0px;
    border-bottom: 1px solid #888888;
  }

  .footerExplore {
    padding: 64px 0px 0px 0px;
  }

  .footerExploreList {
    padding: 16px 0px 0px 0px;
  }

  .footerExploreListItem:nth-child(1) {
    margin: 0px 27px 0px 0px;
  }

  .footerExploreListItem:nth-child(2) {
    margin: 0px 25px 0px 0px;
  }

  .footerAddressText {
    width: 250px;
    padding: 32px 0px 12px 0px;
  }

  .footerAddress {
    background-size: 9px;
    background-position: right 30%;
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --heroFooterText: 12px/14px 'Neue Machina Ultrabold';
    --contacts: 16px/19px 'Neue Machina Regular';
  }

  .footer {
    min-height: 966px;
  }

  .footerContainer {
    padding: 118px 0px 0px 0px;
    flex-direction: column;
  }

  .footerHero {
    padding: 0px 100px 0px 100px;
  }

  .footerHeroText {
    padding: 0px 0px 54px 0px;
  }

  .footerHeroTitle {
    width: 569px;
    height: 207px;
  }

  .footerContacts {
    padding: 90px 0px 0px 403px;
  }

  .footerGetInTouchListItem:first-child {
    margin: 24px 0px 36px 0px;
  }

  .footerGetInTouchListItem:nth-child(2) {
    margin: 0px 0px 12px 0px;
  }

  .footerExplore {
    padding: 64px 0px 0px 0px;
  }

  .footerExploreList {
    padding: 16px 0px 0px 0px;
  }

  .footerExploreListItem:nth-child(1) {
    margin: 0px 27px 0px 0px;
  }

  .footerExploreListItem:nth-child(2) {
    margin: 0px 25px 0px 0px;
  }

  .footerAddressText {
    width: 290px;
    padding: 32px 0px 12px 0px;
  }

}

/* 375px */

@media (max-width: 767px) {

  :root {
    --heroFooterText: 12px/14px 'Neue Machina Ultrabold';
    --contacts: 16px/19px 'Neue Machina Regular';
  }

  .footer {
    min-height: 753px;
  }

  .footerContainer {
    padding: 101px 0px 0px 0px;
  }

  .footerHero {
    padding: 0px 40px 0px 40px;
  }

  .footerHeroText {
    padding: 0px 0px 54px 0px;
  }

  .footerHeroTitle {
    width: 290px;
    height: 102px;
  }

  .footerContacts {
    padding: 73px 0px 0px 56px;
  }

  .footerExplore {
    padding: 64px 0px 0px 0px;
  }

  .footerAddressText {
    width: 285px;
  }

}
