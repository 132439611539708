
/* 3840px */

:root {
  --menuTitle: 24px/28px 'Neue Machina Ultrabold';
  --menuListItem: 72px/86px 'Neue Machina Regular';
  --changeLangText: 40px/48px 'Neue Machina Medium';
}

/* menu */

.menu,
.menuActive {
  position: absolute;
  width: 100%;
  min-height: 2160px;
  height: 100vh;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7; 
}

.menu {
  transform: translateX(+100%);
  transition: transform 0.7s ease-in-out 0.3s;
}

.menuActive { 
  transform: translateX(0);
  transition: transform 0.7s ease-in-out 0.3s;
}

/* menu cross */

.menuCrossContainer {
  position: relative;
}

.menuCross {
  display: block;
  position: relative;
  width: 24px;
  height: 16px;
  left: 0%;
  z-index: 4;
  padding: 31px 28px 31px 28px;
  border-radius: 50%;
  transition: 0.3s ease;
  background-color: #1E1E1E;
  cursor: pointer;
}

.menuCross span {
  position: absolute;
  background-color: #F2F2F2;
  left: 0;
  width: 40%;
  height: 5px;
  top: 45%;
  left: -100%;
  transition: 0.3s ease;
  transform: scale(0);
}

.menuCross:before,
.menuCross:after {
  content: '';
  background-color: #F2F2F2;
  position: absolute;
  width: 40%;
  height: 5px;
  transition: 0.3s ease;
}

.menuCross:before {
  transform: rotate(45deg);
  top: 48%;
  left: 30%;
}

.menuCross:after {
  transform: rotate(-45deg);
  top: 48%;
  left: 30%;
}

/* hover */

.menuCross:hover {
  transition: 0.3s ease;
  background-color: #F2F2F2;
}

.menuCross:hover span {
  background-color: #1E1E1E;
}

.menuCross:hover:before,
.menuCross:hover:after {
  background-color: #1E1E1E;
  transition: 0.3s ease;
}

.menuContainer {
  display: flex;
}

.menuContainerBlur {
  width: 75%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
}

.menuContainerRight {
  width: 25%;
  height: 100vh;
  background-color: #1A1A1A;
}

.menuTitle {
  padding: 0px 0px 0px 96px;
  font: var(--menuTitle);
  letter-spacing: 0.4em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.menuBurgerContainer {
  display: flex;
  justify-content: flex-end;
  padding: 89px 82px 0px 0px;
}

.menuList {
  padding: 308px 0px 325px 96px;
}

.menuListItem:not(:first-child) {
  margin: 108px 0px 0px 0px;
}

.menuListItemText {
  padding: 0px 0px 12px 0px;
  border-bottom: 3px solid #FFFFFF;
  font: var(--menuListItem);
  color: #FFFFFF;
  font-feature-settings: 'ss05' on, 'salt' on, 'ss01' on, 'ss02' on, 'ss03' on, 'ordn' on, 'zero' on;
}

.changeLang {
  margin: 0px 0px 0px 96px;
  width: 145px;
  height: 268px;
  background: no-repeat url('../../../public/content/general/change-lang.svg');
  background-size: 108px 108px;
}

.changeLangText {
  width: 40px;
  margin: 0px 0px 0px 30px;
  padding: 162px 12px 0px 0px;
  font: var(--changeLangText);
  color: #6F6F6F;
  border-bottom: 3px solid #6F6F6F; 
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --menuTitle: 12px/14px 'Neue Machina Ultrabold';
    --menuListItem: 36px/43px 'Neue Machina Regular';
    --changeLangText: 20px/24px 'Neue Machina Medium';
  }

  /* menu */

  .menu,
  .menuActive {
    min-height: 1080px;
  }

  /* menu cross */

  .menuCross {
    width: 25px;
    height: 20px;
    padding: 15px 14px 15px 14px;
  }

  .menuCross span {
    height: 2px;
  }

  .menuCross:before,
  .menuCross:after {
    height: 2px;
  }

  .menuContainerBlur {
    backdrop-filter: blur(10px);
  }

  .menuTitle {
    padding: 0px 0px 0px 49px;
  }

  .menuBurgerContainer {
    padding: 44px 33px 0px 0px;
  }

  .menuList {
    padding: 153px 0px 162px 48px;
  }

  .menuListItem:not(:first-child) {
    margin: 54px 0px 0px 0px;
  }

  .menuListItemText {
    padding: 0px 0px 6px 0px;
    border-bottom: 1.5px solid #FFFFFF;
  }

  .changeLang {
    margin: 0px 0px 0px 48px;
    width: 72px;
    height: 134px;
    background-size: 54px 54px;
  }
  
  .changeLangText {
    width: 20px;
    margin: 0px 0px 0px 15px;
    padding: 80px 6px 0px 0px;
    border-bottom: 1.5px solid #6F6F6F; 
  }

}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --menuTitle: 12px/14px 'Neue Machina Ultrabold';
    --menuListItem: 36px/43px 'Neue Machina Regular';
    --changeLangText: 20px/24px 'Neue Machina Medium';
  }

  /* menu */

  .menu,
  .menuActive {
    min-height: 720px;
  }

  /* menu cross */

  .menuCross {
    width: 10px;
    height: 6px;
    padding: 15px 14px 15px 14px;
  }

  .menuCross span {
    height: 2px;
  }

  .menuCross:before,
  .menuCross:after {
    /* left: 30%; */
    height: 2px;
  }

  .menuTitle {
    padding: 0px 0px 0px 32px;
  }

  .menuBurgerContainer {
    padding: 22px 22px 0px 0px;
  }

  .menuList {
    padding: 102px 0px 108px 32px;
  }

  .menuListItem:not(:first-child) {
    margin: 36px 0px 0px 0px;
  }

  .menuListItemText {
    padding: 0px 0px 4px 0px;
    border-bottom: 1px solid #FFFFFF;
  }

  .changeLang {
    margin: 0px 0px 0px 32px;
    width: 48px;
    height: 89px;
    background-size: 36px 36px;
  }
  
  .changeLangText {
    width: 20px;
    margin: 0px 0px 0px 6px;
    padding: 50px 4px 0px 0px;
    border-bottom: 1px solid #6F6F6F; 
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --menuTitle: 12px/14px 'Neue Machina Ultrabold';
    --menuListItem: 36px/43px 'Neue Machina Regular';
  }

  /* menu */

  .menu,
  .menuActive {
    min-height: 1024px;
    background-color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuContainerBlur {
    display: none;
  }

  .menuContainerRight {
    width: 320px;
    height: 720px;
  }

  .menuTitle {
    padding: 0px 0px 0px 32px;
  }

  .menuBurgerContainer {
    padding: 22px 22px 0px 0px;
  }

  .menuList {
    padding: 102px 0px 108px 32px;
  }

  .menuListItem:not(:first-child) {
    margin: 36px 0px 0px 0px;
  }

}

/* 375px */

@media (max-width: 767px) {

  :root {
    --menuTitle: 12px/14px 'Neue Machina Ultrabold';
    --menuListItem: 36px/43px 'Neue Machina Regular';
  }

  /* menu */

  .menu,
  .menuActive {
    min-height: 667px;
    align-items: flex-start;
  }

  .menuContainerRight {
    width: 375px;
    height: 667px;
  }

  .menuList {
    padding: 48px 0px 54px 32px;
  }

  .menuBurgerContainer {
    padding: 22px 11px 0px 0px;
  }

}
