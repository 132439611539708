
/* 3840px */

:root {
  --headerText: 72px/72px 'Neue Machina Medium';
  --titleText: 193px/231px 'Syne Bold';
  --mailText: 32px/38px 'Neue Machina Regular';
  --adressFooterText: 34px/41px 'Neue Machina Regular';
}

.contact {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.contactMenu {
  position: fixed;
  width: 100%;
  z-index: 4;
}

.contactContainer {
  width: 95%;
  height: 75%;
  position: absolute;
  z-index: 2;
  padding: 105px 66px 0px 107px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titlePoint {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #F84525;
}

.contactBody {
  width: 1565px;
  padding: 0px 0px 0px 300px;
}

.contactBody p {
  margin: 0px 0px 32px 0px;
}

.contactBodyHeader {
  font: var(--headerText);
  color: #F84525;
}

.contactBodyTitle {
  text-decoration-line: underline;
  font: var(--titleText);
  color: #FFFFFF;
}

.contactBodyFooter {
  width: 230px;
  background: no-repeat url('../../../public/content/contact/contact-arrow.svg');
  background-position: 90% 40%;
  background-size: 30px;
  border-bottom: 3px solid transparent;
  transition: 0.2s ease-in-out;
}

.contactBodyFooter:hover {
  background-position: 100% 40%;
  border-bottom: 3px solid #FFFFFF;
  transition: 0.2s ease-in-out;
}

.contactBodyMail {
  font: var(--mailText);
  color: #FFFFFF;
}

.contactImage {
  width: 3040px;
  height: 2150px;
  position: absolute;
  left: 20%;
  z-index: 1;
  opacity: 0.5;
}

.contactFooter {
  display: flex;
  padding: 0px 0px 0px 300px;
}

.mail,
.phone {
  margin: 0px 0px 0px 72px;
}

.address,
.mail,
.phone {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  border-radius: 72px;
  transition: 0.5s ease-in-out 0.4s;
  cursor: pointer;
}

.addressIsActive,
.mailIsActive,
.phoneIsActive {
  background-color: rgba(30, 30, 30, 0.6);
  transition: all 0.5s ease-in-out;
}

.addressText,
.mailText,
.phoneText {
  font: var(--adressFooterText);
  margin: 0px 0px 0px 300px;
  color: #FFFFFF;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.addressIsActive .addressText,
.mailIsActive .mailText,
.phoneIsActive .phoneText {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.4s;
  pointer-events: all;
}

.address {
  background: no-repeat url('../../../public/content/contact/address.svg');
  background-position: 50% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

.addressIsActive {
  width: 1200px;
  background: no-repeat url('../../../public/content/contact/address-hover.svg');
  background-position: 10% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

.mail {
  background: no-repeat url('../../../public/content/contact/mail.svg');
  background-position: 50% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

.mailIsActive {
  width: 700px;
  background: no-repeat url('../../../public/content/contact/mail-hover.svg');
  background-position: 10% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

.phone {
  flex-direction: column;
  justify-content: center;
  background: no-repeat url('../../../public/content/contact/phone.svg');
  background-position: 50% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

.phoneIsActive {
  width: 700px;
  background: no-repeat url('../../../public/content/contact/phone-hover.svg');
  background-position: 10% 50%;
  background-size: 130px;
  background-color: rgba(30, 30, 30, 0.6);
}

/* 1920px */

@media (max-width: 3839px) {

  :root {
    --headerText: 36px/36px 'Neue Machina Medium';
    --titleText: 96px/115px 'Syne Bold';
    --mailText: 32px/38px 'Neue Machina Regular';
    --adressFooterText: 16px/19px 'Neue Machina Regular';
  }

  .contactContainer {
    padding: 52px 33px 0px 50px;
  }

  .titlePoint {
    width: 8px;
    height: 8px;
  }

  .contactBody {
    width: 780px;
    padding: 0px 0px 0px 200px;
  }
  
  .contactBody p {
    margin: 0px 0px 15px 0px;
  }

  .contactImage {
    width: 1536px;
    height: 1000px;
    left: 25%;
  }

  .contactFooter {
    padding: 0px 0px 0px 200px;
  }

  .mail,
  .phone {
    margin: 0px 0px 0px 36px;
  }

  .address,
  .mail,
  .phone {
    width: 150px;
    height: 150px;
    border-radius: 36px;
  }

  .addressText,
  .mailText,
  .phoneText {
    margin: 0px 80px 0px 200px;
  }

  .address {
    background-size: 96px;
  }

  .addressIsActive {
    width: 720px;
    background-size: 96px;
  }

  .mail {
    background-size: 96px;
  }

  .mailIsActive {
    width: 350px;
    background-size: 96px;
  }

  .phone {
    background-size: 96px;
  }

  .phoneIsActive {
    width: 500px;
    background-size: 96px;
  }

  .contactBodyFooter {
    border-bottom: 1.5px solid transparent;
  }
  
  .contactBodyFooter:hover {
    border-bottom: 1.5px solid #FFFFFF;
  }

}

/* 1280px */

@media (max-width: 1919px) {

  :root {
    --headerText: 24px/24px 'Neue Machina Medium';
    --titleText: 64px/77px 'Syne Bold';
    --mailText: 32px/38px 'Neue Machina Regular';
  }

  .contactContainer {
    padding: 35px 22px 0px 36px;
  }

  .contactBody {
    width: 520px;
    padding: 0px 0px 0px 133px;
  }
  
  .contactBody p {
    margin: 0px 0px 10px 0px;
  }

  .contactImage {
    width: 1024px;
    height: 700px;
    left: 25%;
  }

  .contactFooter {
    padding: 0px 0px 0px 133px;
  }

  .mail,
  .phone {
    margin: 0px 0px 0px 24px;
  }

  .address,
  .mail,
  .phone {
    width: 100px;
    height: 100px;
    border-radius: 24px;
  }

  .addressText,
  .mailText,
  .phoneText {
    margin: 0px 20px 0px 120px;
  }

  .address {
    background-size: 64px;
  }

  .addressIsActive {
    width: 450px;
    background-size: 64px;
  }

  .mail {
    background-size: 64px;
  }

  .mailIsActive {
    width: 250px;
    background-size: 64px;
  }

  .phone {
    background-size: 64px;
  }

  .phoneIsActive {
    width: 350px;
    background-size: 64px;
  }

  .contactBodyFooter {
    border-bottom: 1px solid transparent;
  }
  
  .contactBodyFooter:hover {
    border-bottom: 1px solid #FFFFFF;
  }

}

/* 768px */

@media (max-width: 1279px) {

  :root {
    --headerText: 24px/24px 'Neue Machina Medium';
    --titleText: 64px/77px 'Syne Bold';
    --mailText: 32px/38px 'Neue Machina Regular';
  }

  .contact {
    /* min-height: 1440px; */
  }

  .contactContainer {
    width: 93%;
    height: 65%;
    padding: 35px 37px 0px 47px;
  }

  .menu {
    padding: 0px 22px 0px 0px;
  }

  .contactBody {
    width: 520px;
    padding: 30px 0px 30px 84px;
  }
  
  .contactBody p {
    margin: 0px 0px 10px 0px;
  }

  .contactImage {
    width: 768px;
    height: 650px;
    top: 36%;
    left: 0%;
    opacity: 1;
  }

  .contactFooter {
    flex-direction: column;
    padding: 0px 0px 0px 84px;
  }

  .mail,
  .phone {
    margin: 24px 0px 0px 0px;
  }

}

/* 367px */

@media (max-width: 767px) {

  :root {
    --headerText: 24px/24px 'Neue Machina Medium';
    --titleText: 48px/58px 'Syne Bold';
    --mailText: 32px/38px 'Neue Machina Regular';
  }

  .contact {
    min-height: 800px;
    overflow-y: hidden;
  }

  .contactContainer {
    width: 93%;
    height: 100%;
    padding: 22px 11px 0px 12px;
    justify-content: start;
  }
  
  .menu {
    padding: 0px 11px 0px 0px;
  }

  .titlePoint {
    display: none;
  }

  .contactBody {
    width: 290px;
    padding: 20px 0px 0px 0px;
  }

  .contactBodyFooter {
    margin: 10px 0px 0px 0px;
  }
  
  .contactImage {
    width: 375px;
    height: 650px;
    top: 30%;
    left: 0%;
    opacity: 1;
  }

  .contactImage img{
    scale: 1.3;
  }

  .contactFooter {
    padding: 20px 0px 0px 0px;
  }

  .mail,
  .phone {
    margin: 24px 0px 0px 0px;
  }

  .addressText,
  .mailText,
  .phoneText {
    margin: 0px 20px 0px 100px;
  }

  .addressIsActive {
    width: 350px;
    background-size: 64px;
  }

  .mailIsActive {
    width: 250px;
  }

  .phone {
    background-size: 64px;
  }

  .phoneIsActive {
    width: 300px;
    background-size: 64px;
  }

  .footer {
    display: block;
  }

}



